import { createSlice } from "@reduxjs/toolkit";
import Toast from "../chakra/Toast";
// const Allowance = () => {
//   useEffect(() => {
//     effect
//     return () => {
//       cleanup
//     }
//   }, [])
// }
// const Toast = (title, description, error) => {
//   const toast = useToast();
//   toast({
//     title: title,
//     description: description,
//     status: error,
//     position: "top-right",
//   });
// };
export const compareSlice = createSlice({
  name: "comparelist",
  initialState: {
    value: sessionStorage.getItem("comparelist")
      ? JSON.parse(sessionStorage.getItem("comparelist"))
      : [],
  },
  reducers: {
    addCompare: (state, action) => {
      let found = false;
      state.value.map((item, index) => {
        if (item.id === action.payload.id) {
          // alert("Vehicle Already exist");
          // const toast = useToast();
          Toast();
          found = true;
          return state;
        }
        return state;
      });

      if (state.value.length > 2) {
        found = true;
        alert("Compare list full.");
        return state;
      }
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (!found) {
        let newlist = [...state.value, action.payload];
        sessionStorage.setItem("comparelist", JSON.stringify(newlist));
        state.value = newlist;
      }
      return state;
      // console.log(state.value);
      // state.value = action.payload;
    },
    removeCompare: (state, action) => {
      //remove compare
      let newlist = [];
      state.value.map((compare) => {
        if (compare.id !== action.payload.id) {
          newlist.push(compare);
        }
        return state;
      });
      sessionStorage.setItem("comparelist", JSON.stringify(newlist));
      state.value = newlist;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCompare, removeCompare } = compareSlice.actions;

export default compareSlice.reducer;
