import React from "react";
import { Link } from "react-router-dom";

function TopBar() {
  return (
    <div className="wo-header-topbarwrap d-none d-sm-block">
      <div className="container-fluid">
        <div className="row">
          <div className="wo-topbarcontent">
            <ul className="wo-topbarcontent__moreinfo">
              {/* <li>
                <Link to="#">
                  <i className="fa fa-phone-alt"></i>+263 77 578 8875
                </Link>
              </li> */}
              {/* <li>
                <address>
                  <i className="fa fa-map-marker-alt"></i>1 Borrowdate Road,
                  Harare, Zimbabwe
                </address>
              </li> */}
              {/* <li>
                <Link to="#" data-toggle="modal" data-target="#inspectionpopup">
                  Get Your Car Inspection FREE
                </Link>
              </li> */}
            </ul>
            <div className="wo-topbarcontent__social">
              <ul className="wo-socialmedia">
                <li>
                  <span>Follow Us:</span>
                </li>
                <li className="wo-facebook">
                  <Link to="#">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li className="wo-linkedin">
                  <Link to="#">
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
                <li className="wo-twitter">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li className="wo-googleplus">
                  <Link to="#">
                    <i className="fab fa-google"></i>
                  </Link>
                </li>
                <li className="wo-youtube">
                  <Link to="#">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
                <li className="wo-instagram">
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
