import { createSlice } from "@reduxjs/toolkit";
export const myVehicleSlice = createSlice({
  name: "myvehicles",
  initialState: {
    value: localStorage.getItem("myvehicles")
      ? JSON.parse(localStorage.getItem("myvehicles"))
      : [],
  },
  reducers: {
    addVehicle: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      let found = -1;

      state.value.map((item, index) => {
        if (
          JSON.stringify(item) === JSON.stringify(action.payload)
        ) {
          // alert("Vehicle Already exist");
          // const toast = useToast();
          found = 1
        }
        return state;
      });

      if (found < 0) {
        let newlist = [...state.value, action.payload];
        localStorage.setItem("myvehicles", JSON.stringify(newlist));
        state.value = newlist;
      } 

      return state;
    },
    removeVehicle(state, action) {
      let newcart = [...state.value];
      for (let index = 0; index < newcart.length; index++) {
        const element = newcart[index];

        if (
          JSON.stringify(action.payload) ===
          JSON.stringify(element)
        ) {
          newcart.splice(index, 1);
        }
      }

      localStorage.setItem("myvehicles", JSON.stringify(newcart));
      state.value = newcart;

      return state;
    }
  },
});

// Action creators are generated for each case reducer function
export const { addVehicle, removeVehicle } = myVehicleSlice.actions;

export default myVehicleSlice.reducer;
