import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axios";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

function PartsList() {
  const { category_slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [myoption, setMyOption] = useState();
  const [pages, setPages] = useState(1);
  const [parts, setParts] = useState([]);
  const { search } = useLocation();

  let searchParams = new URLSearchParams(window.location.search);

  const option = useSelector((state) => state.myvehicle.value);
  const myvehicles = useSelector((state) => state.myvehicles.value);
  console.log(myoption);

  useEffect(() => {
    let myoptions = [];
    myvehicles.map((myvehicle) => {
      myoptions.push({
        value:
          myvehicle.make.id +
          "-MOTION-" +
          myvehicle.variation.value +
          "-MOTION-" +
          myvehicle.engine.value,
        label:
          myvehicle.make.name +
          " " +
          myvehicle.variation.label +
          " " +
          myvehicle.engine.label,
      });
      if (
        myvehicle.make.id +
          "-MOTION-" +
          myvehicle.variation.value +
          "-MOTION-" +
          myvehicle.engine.value ===
        option
      ) {
        searchParams.set("make", myvehicle.make.value);
        searchParams.set("variation", myvehicle.variation.value);
        searchParams.set("engine", myvehicle.engine.value);
        //setMyOption(myvehicle);
      }
      return false;
    });

    setLoading(true);
    let url = "ajax/parts/";
    if (category_slug) {
      url += category_slug;
    }

    axiosInstance
      .get(url + "?" + searchParams.toString())
      .then((res) => {
        if (res.data.results) {
          setPages(res.data.pages);
          setParts(res.data.results);
          setLoading(false);
        }
      })
      .then();
  }, [category_slug, search]);

  function loadmore(data) {
    setLoading(true);
    setParts([]);
    document.documentElement.scrollTop = 0;
    let url = "ajax/parts/";
    if (category_slug) {
      url += category_slug;
    } else {
      url += "all";
    }

    axiosInstance
      .get(url + "/" + (data.selected + 1) + "?" + searchParams.toString())
      .then((res) => {
        if (res.data.results) {
          setLoading(false);
          setPages(res.data.pages);
          setParts(res.data.results);
        }
        setLoading(false);
      })
      .then();
  }

  return (
    <>
      {loading && (
        <Center className="wo-banner__formwrap w-100 mt-5 mb-5">
          <Spinner size="xl" color="blue.300" />
        </Center>
      )}
      {!loading && (
        <div className="row">
          {parts.length === 0 && (
            <Center className="wo-banner__formwrap text-center w-100 mt-5 mb-5">
              <h3>
                <i className="far fa-folder-open mr-2"></i>No Products Found
              </h3>
            </Center>
          )}
          {parts.map((part) => (
            <div
              className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 "
              key={part.id}
            >
              <div className="wo-topvehicles mb-3">
                <div className="wo-vehiclesslider">
                  {part.images.length > 0 && (
                    <img
                      className="w-100"
                      src={`${part.image_base}${part.images[0]}`}
                      alt={part.name}
                    />
                  )}
                  <div className="wo-vehiclesimg-tags">
                    <div className="wo-tags">
                      {part.shipping && (
                        <Link to="#" className="wo-tag-featured">
                          Free Delivery
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="wo-vehicles">
                  <div className="wo-vehicles__tags">
                    <small>
                      {part.category.name} / {part.category.subcategory.name}
                    </small>
                  </div>
                  <div className="wo-vehicles__title">
                    <h5>
                      <Link to={`/part/${part.id}`} className="h6">
                        {part.name}
                      </Link>
                      <span>
                        <sup>$</sup>
                        {part.variations[0].variation_price}
                      </span>
                    </h5>
                  </div>
                  <Link to={`/part/${part.id}`} className="wo-btn w-100">
                    Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <nav className="wo-pagination">
        {pages > 1 && (
          <ReactPaginate
            pageCount={pages}
            previousLinkClassName="wo-prevpage"
            nextLinkClassName="wo-nextpage"
            activeClassName={"wo-active"}
            previousLabel={"PRV"}
            nextLabel={"NEX"}
            onPageChange={loadmore}
          />
        )}
      </nav>
    </>
  );
}

export default PartsList;
