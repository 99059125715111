import React, { useState, useEffect } from 'react';
import Select from 'react-select';


const Steering = ({steering}) => {

    const [option, setOption] = useState();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));


    useEffect(() => {

        if(steering){
            let array = Object.entries(steering);
            let steeringOptions = [];
            array.map((arr) => {
                steeringOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('steering') === arr[0]) {
                    setOption(steeringOptions[steeringOptions.length - 1]);
                }
                return arr;
            });
    
    
            setOptions(steeringOptions);
    
            setLoading(false);
        }
        

    }, [steering,urlParams])


    return (
        <Select
        className="w-100 react-select-container mr-1"
        placeholder="Steering"
        isSearchable
        name="steering"
        value={option}
        isClearable
        isLoading={loading}
        options={options}
        onChange={(e) => {
            if (!e) {
                urlParams.delete("steering");
                //urlParams.set("page",1);
                setOption(null);
            } else {
                setOption(e);
                urlParams.set("steering", e.value);
            }

           
        }}
        />
    )
}

export default Steering
