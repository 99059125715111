import React, { useEffect, useState } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axiosInstance from "../../helpers/axios";

function CategoriesGrid() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axiosInstance
      .get("ajax/parts-categories")
      .then((res) => {
        setLoading(false);
        if (Array.isArray(res.data)) {
          setCategories(res.data);
        }
      })
      .then()
      .catch(() => {
        setLoading(false);
      });
  }, []);

  //const showSubCategories = (subcategories) => {};

  if (loading) {
    return (
      <Center>
        <Spinner size="xl" color="blue.300" />
      </Center>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="wo-bodytype">
            {categories.map((category) => (
              <li key={category.id}>
                <img src={category.icon} alt="img description"></img>
                <h3>{category.name}</h3>
                <Link to={'/parts?category='+category.slug}>View All</Link>
                <div></div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default CategoriesGrid;
