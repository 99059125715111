import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/header/Header";
import axiosInstance from "../helpers/axios";
import { useAuth } from "../helpers/google/AuthContext";
import { auth } from "../helpers/google/firebase";
import Select from "react-select";
import { Center, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { clearCart } from "../helpers/redux/cartSlice";

function CheckoutPage() {
  const { currentUser } = useAuth();
  const [delivery, setDelivery] = useState("delivery");
  const [pickups, setPickups] = useState([]);
  const [locations, setLocations] = useState([]);
  const [address, setAddress] = useState("Select Delivery Method");

  const [shipping, setShipping] = useState(0);
  const [payusing, setPayUsing] = useState("cash");
  const [isloading, setIsLoading] = useState(false);
  const [isinit, setIsInit] = useState(true);
  const [serverMessage, setServerMessage] = useState("");
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart.value);

  const [fullname, setFullName] = useState(currentUser ? currentUser.displayName : "",);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [notes, setNotes] = useState("");
  const [pickup, setPickup] = useState("");
  const [house, setHouse] = useState("");
  const [apartment, setApartment] = useState("");
  const [location, setLocation] = useState("");

  const [token, setToken] = useState("new");

  const deliveryOptions = [
    {
      label: "Pickup",
      value: "Pickup"
    },
    {
      label: "Delivery",
      value: "Delivery"
    }
  ];



  const validate = (values) => {
    const errors = {};
    console.log(values);
    if (values.fullname.length < 3) {
      errors.fullname = "Full Name is required";
    }

    if (values.email.length < 3) {
      errors.email = "Email is required";
    }
    if (values.phone.length < 6) {
      errors.phone = "Phone is required";
    }
    if (delivery === "Pickup") {
      if (values.pickup.length < 1) {
        errors.pickup = "Pickup is required";
      }
    } else if (delivery === "Delivery") {
      if (values.house.length < 3) {
        errors.house = "House number and street name required";
      }
      if (!values.location) {
        errors.location = "Please select a location for delivery";
      }
    }

    console.log(errors);
    return errors;
  };
  function handleCheckOut() {
    setIsLoading(true);
    setIsInit(false);
    setServerMessage("");
    let data = {
      cart: cart,
      fullname: fullname,
      email: email,
      phone: phone,
      house: house,
      apartment: apartment,
      location: location,
      pickup: pickup,
      method: delivery,
      payment: payusing,
    };

    axiosInstance
      .post("ajax/order", JSON.stringify(data), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setIsInit(true);
          setServerMessage(
            response.data.message
              ? response.data.message
              : "Error occured while trying to created order try again or try different payment method"
          );
          setIsLoading(false);
        } else if (
          response.data.error !== undefined &&
          response.data.error === false
        ) {
          dispatch(clearCart());
          setIsLoading(false);
          setServerMessage(response.data.message);
          if (response.data.redirect) {
            window.location.href = response.data.redirect;
          }
        } else {
          setIsInit(true);
          setServerMessage(
            response.data.message
              ? response.data.message
              : "Error occured while trying to created order try again or try different payment method"
          );
          setIsLoading(false);
        }
      });
  }
  const formik = useFormik({
    initialValues: {
      fullname: fullname,
      email: email,
      phone: phone,
      house: house,
      apartment: apartment,
      location: location,
      pickup: pickup,
    },
    validate,
    onSubmit: (value) => {
      console.log(value);
      handleCheckOut();
    },
  });

  useEffect(() => {
    setIsLoading(true);

    // get pickups and delivery address
    axiosInstance
      .get('ajax/addresses')
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setPickups(data.pickups);
        setLocations(data.deliveries);
        if (data.pickups.length > 0) {
          setDelivery("Pickup");
        }
        if (data.pickups.length < 1 && data.deliveries.length > 0) {
          setDelivery("Delivery");
        }
        setIsLoading(false);
      });

    // setPickups(pickupaddresses);
    // setLocations(deliveryaddresses);


    auth.onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (idToken) {
          setToken(idToken);
        });
      }
    });

    setFullName(currentUser ? currentUser.displayName : "");
    setEmail(currentUser ? currentUser.email : "");

  }, [currentUser]);

  return (
    <>
      <Header type="parts" />
      <div className="wo-main">
        <section className="wo-main-section">
          <div className="container">
            <form className="w-100" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div
                  className={`col-sm-12 col-md-12 col-lg-5 offset-lg-1 ${isinit ? "" : "d-none"
                    }`}
                >
                  {isloading ? (
                    <Center className="wo-banner__formwrap w-100 h-100">
                      <Spinner size="xl" color="orange.300" />
                    </Center>
                  ) : (
                    <div className="wo-dbpost mb-3">
                      <div className="wo-dnavbar__title">
                        <h3>Your Details</h3>
                      </div>
                      <div className="p-3 w-100">
                        <p className="small text-danger mb-0 mt-2">
                          {formik.errors.fullname}
                        </p>
                        <div className="form-group">
                          <label htmlFor="full-name">
                            <input
                              type="text"
                              id="full-name"
                              name="fullname"
                              placeholder="Full Name"
                              className="form-control"
                              value={fullname}
                              onChange={(e) => {
                                formik.values.fullname = e.target.value;
                                setFullName(e.target.value);
                              }}
                            />
                          </label>
                        </div>
                        <p className="small text-danger mb-0 mt-2">
                          {formik.errors.email}
                        </p>
                        <div className="form-group">
                          <label htmlFor="email">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Email"
                              className="form-control"
                              value={email}
                              onChange={(e) => {
                                formik.values.email = e.target.value;
                                setEmail(e.target.value);
                              }}
                            />
                          </label>
                        </div>
                        <p className="small text-danger mb-0 mt-2">
                          {formik.errors.phone}
                        </p>
                        <div className="form-group">
                          <label htmlFor="phone">
                            <input
                              type="phone"
                              id="phone"
                              name="phone"
                              placeholder="Phone Number"
                              className="form-control"
                              value={phone}
                              onChange={(e) => {
                                formik.values.phone = e.target.value;
                                setPhone(e.target.value);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="wo-dnavbar__title mb-2">
                        <h3>Delivery / Pickup</h3>
                      </div>
                      <div className="p-3 w-100">
                        <div
                          className="w-100"
                          style={{ position: "relative" }}
                        >
                          {/* <select name="" className="react-select-container form-control" id="" onChange={(e) => {
                            console.log(e.target.options[e.target.options.selectedIndex].value);
                            setDelivery(e.target.options[e.target.options.selectedIndex].value);
                          }}>
                            <option value="Pickup" selected={delivery == "Pickup" ? "true" : "false"}>Pickup</option>
                            <option value="Delivery" selected={delivery == "Delivery" ? "true" : "false"}>Deliv</option>
                            </select> */}

                          <Select
                            className="react-select-container"
                            placeholder="Fullfillment Options"
                            isSearchable
                            required
                            onChange={(e) => {
                              console.log(e);
                              setPickup("");
                              setLocation("");
                              if (e.value === "Pickup") {
                                setAddress("Select pickup location");
                                setShipping(0);
                                formik.values.house = "";
                                formik.values.apartment = "";
                                formik.values.location = "";
                              } else {
                                setAddress("Select delivery location");
                                setShipping(0);
                                formik.values.pickup = "";
                              }
                              setDelivery(e.value);
                            }}
                            options={deliveryOptions}
                            value={deliveryOptions.filter(function (option) {
                              return option.value === delivery;
                            })}
                          />

                          {/* {pickups.length > 0 && (
                            <span className="wo-radio">
                              <input
                                type="radio"
                                id="pickup"
                                name="variation"
                                value="pickup"
                                readOnly
                                checked={delivery == "Pickup" ? "true" : "false"}
                                onClick={() => {
                                  setAddress("Select pickup location");
                                  setDelivery("Pickup");
                                  setShipping(0);
                                  formik.values.house = "";
                                  formik.values.apartment = "";
                                  formik.values.location = "";
                                }}
                              />
                              <label htmlFor="pickup" onClick={() => { }}>
                                <span>Pickup</span>
                              </label>
                            </span>
                          )}

                          {locations.length > 0 && (
                            <span className="wo-radio">
                              <input
                                type="radio"
                                id="delivery"
                                name="variation"
                                value="delivery"
                                readOnly
                                onClick={() => {
                                  setAddress("Select delivery location");
                                  setDelivery("Delivery");
                                  setShipping(0);
                                  formik.values.pickup = "";
                                }}
                                checked={delivery == "Delivery" ? "true" : "false"}
                              />
                              <label htmlFor="delivery" onClick={() => { }}>
                                <span>Delivery</span>
                              </label>
                            </span>
                          )} */}
                        </div>
                      </div>
                      {delivery === "Pickup" && (
                        <div className="ml-3 mr-3 mb-3">
                          <p className="small text-danger mb-0 mt-2">
                            {formik.errors.pickup}
                          </p>
                          <small>Select Pickup Location</small>

                          <Select
                            className="react-select-container"
                            placeholder="Pickup Locations"
                            isSearchable
                            required
                            onChange={(e) => {
                              console.log(e);
                              setAddress(e.label);
                              setPickup(e.value);
                              formik.values.pickup = e.value;
                            }}
                            name="pickuplocations"
                            options={pickups}
                            value={pickups.filter(function (option) {
                              return option.value === pickup;
                            })}
                          />
                        </div>
                      )}
                      {delivery === "Delivery" && (
                        <div className="ml-3 mr-3 mb-3">
                          <p className="small text-danger mb-0 mt-2">
                            {formik.errors.house}
                          </p>
                          <div className="form-group">
                            <label htmlFor="house">
                              <input
                                type="text"
                                id="house"
                                name="house"
                                value={house}
                                placeholder="House Number and Street Name"
                                className="form-control"
                                onChange={(e) => {
                                  setHouse(e.target.value);
                                  formik.values.house = e.target.value;
                                }}
                              />
                            </label>
                          </div>

                          <div className="form-group">
                            <label htmlFor="apartment">
                              <input
                                type="text"
                                id="apartment"
                                name="apartment"
                                placeholder="Apartment, suite, unit etc (optional)"
                                className="form-control"
                                value={apartment}
                                onChange={(e) => {
                                  setApartment(e.target.value);
                                }}
                              />
                            </label>
                          </div>
                          <p className="small text-danger mb-0 mt-2">
                            {formik.errors.location}
                          </p>
                          <Select
                            className="react-select-container"
                            placeholder="Select Location"
                            isSearchable
                            onChange={(e) => {
                              setAddress(e.label);
                              setShipping(e.price);
                              setLocation(e.value);
                              formik.values.location = e.value;
                            }}
                            name="deliveryoptions"
                            options={locations}
                            value={locations.filter(function (option) {
                              return option.value === location;
                            })}
                          />
                        </div>
                      )}
                      <div className="wo-dnavbar__title mb-2">
                        <h3>Order notes</h3>
                      </div>
                      <div className="p-3 w-100">
                        <div className="form-group">
                          <label htmlFor="notes">
                            <textarea
                              type="text"
                              id="notes"
                              name="notes"
                              placeholder="Order notes (optional)"
                              className="form-control"
                              value={notes}
                              onChange={(e) => {
                                setNotes(e.target.value);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ minHeight: "400px" }}
                  className={`col-sm-12 col-md-12 col-lg-5 offset-lg-1 ${isinit ? "d-none" : ""
                    }`}
                >
                  {isloading && (
                    <Center className="wo-banner__formwrap w-100 h-100">
                      <Spinner size="xl" color="orange.300" />
                    </Center>
                  )}
                  {serverMessage && !isloading && (
                    <div className="wo-fpost">
                      <img
                        src="images/footer/img-02.jpg"
                        alt="img description"
                      />
                      <h4>Order Placed</h4>
                      <p className="text-muted">{serverMessage}</p>
                      <Link to="/">Go Home</Link>
                    </div>
                  )}
                </div>
                <div className={`col-sm-12 col-md-12 col-lg-5`}>
                  <div className="wo-settingsave p-2">
                    <div className="wo-settingsave__title">
                      <h2>Your Order</h2>
                      <span>Here is your order summary</span>
                    </div>

                    {cart.length > 0 ? (
                      <>
                        <div className="wo-dbpost p-3 mt-5">
                          <table className="order-summary">
                            <tbody>
                              {cart.map((item, index) => (
                                <tr key={index}>
                                  <td className="product-summary">
                                    <h3>
                                      <span className="product-title">
                                        {item.name}
                                      </span>
                                      <span className="item-quantity-price">
                                        {item.count} x $
                                        {item.variation.variation_price}
                                      </span>
                                    </h3>
                                    <p>
                                      Variation: {item.variation.variation_name}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <hr />
                          <table className="checkout-subtotals">
                            <tbody className="d-block">
                              <tr>
                                <td className="label">SubTotal</td>
                                <td>
                                  $
                                  {cart.reduce(
                                  (acc, curr) =>
                                    acc +
                                    curr.count *
                                    curr.variation.variation_price,
                                  0
                                )}
                                </td>
                              </tr>

                              <tr>
                                <td className="label">
                                  {delivery} ({address})
                                </td>
                                <td>${shipping}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="mt-3 d-flex">
                            <strong style={{ flexGrow: 1 }}>Total</strong>
                            <strong>
                              $
                              {parseFloat(
                              cart.reduce(
                                (acc, curr) =>
                                  acc +
                                  curr.count * curr.variation.variation_price,
                                0
                              )
                            ) + parseFloat(shipping)}
                            </strong>
                          </div>

                          {isinit && (
                            <>
                              <hr /> <p>Payment Methods</p>
                              <div>
                                {/* <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="payusing"
                                    id="cash1"
                                    defaultChecked={payusing == "card" && true}
                                    onChange={(e) => {
                                      setPayUsing("card");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cash1"
                                  >
                                    Pay Now
                                  </label>
                                </div> */}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="payusing"
                                    id="flexRadioDefault2"
                                    defaultChecked={payusing === "cash" && true}
                                    onChange={(e) => {
                                      setPayUsing("cash");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault2"
                                  >
                                    Cash on {delivery}
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {serverMessage && isinit && (
                          <div
                            className="alert alert-danger alert-dismissible fade show mt-3"
                            role="alert"
                          >
                            {serverMessage}
                          </div>
                        )}
                        {!isloading && isinit && (
                          <button
                            type="submit"
                            disabled={isloading}
                            className="wo-btn mt-3"
                          >
                            Checkout
                          </button>
                        )}
                      </>
                    ) : (
                      <div className="wo-dbpost p-3 mt-5">
                        <h6 className="text-center">
                          <i className="fas fa-shopping-cart h3"></i>
                          <br></br>Cart is empty!
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default CheckoutPage;
