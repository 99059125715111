import React from 'react'
import ContactForm from '../components/basic/ContactForm'
import Header from '../components/header/Header'

function ContactUs() {
    return (
        <>
           <Header /> 
            <div className="wo-innerbannertwo-wrap bg-color2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="wo-innerbannertwo">
                                <div className="wo-innerbannertwo__title">
                                    <h4><span>We Love What You Say</span>Stay Posted, Stay Together</h4>
                                </div>
                                <div className="wo-innerbannertwo__description">
                                    {/* <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore etdolore magna aliqua enim adinim ullamco laborisn nisi ut aliquip</p> */}
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <figure className="wo-innerbannertwo-img">
                                <img src="images/inner-banner/img-03.png" alt="img description" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div id="particles-js" className="wo-particles particles-js" />
            </div>
            <main className="wo-main overflow-hidden">
                <section className="wo-main-section">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-sm-12 col-md-7">
                                <ContactForm />
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="wo-ouravailability">
                                    <div className="wo-ouravailability__title">
                                        <h3>Our Availability Details</h3>
                                    </div>
                                    <ul className="wo-ouravailability__info">
                                        <li>
                                            <figure className="wo-contactimg">
                                                <img src="images/contact/img-01.jpg" alt="img description" />
                                            </figure>
                                            <div className="wo-contactinfo">
                                                <span>Our Voice Numbers</span>
                                                <h4><a href="tel:+263775788875"> +263 77 578 8875</a><a href="tel:+263778362387">++263 77 836 2387</a></h4>
                                            </div>
                                        </li>
                                        <li>
                                            <figure className="wo-contactimg">
                                                <img src="images/contact/img-03.jpg" alt="img description" />
                                            </figure>
                                            <div className="wo-contactinfo">
                                                <span>Email Us If Its Easy For You</span>
                                                <h4><a href="mailto:info@motion7.co.zw"> info@motion7.co.zw</a><a href="mailto:info@motion7.color"> support@motion7.co.zw</a></h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default ContactUs
