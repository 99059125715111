import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Fuel from "./search/Fuel";
import Body from "./search/Body";
import { setMakes } from "../../helpers/redux/makeSlice";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../helpers/axios";
import Vehicle from "./search/Vehicle";
import Transmission from "./search/Transmission";
import Steering from "./search/Steering";
import DriveTrain from "./search/DriveTrain";
import Color from "./search/Color";
import Assembly from "./search/Assembly";
import MaxPrice from "./search/MaxPrice";

const SearchHead = ({ searchData }) => {
  // const [makes, setMakes] = useState([]);
  const vehicles = useSelector((state) => state.vehicles.value);
  const makes = useSelector((state) => state.makes.value);
  const dispatch = useDispatch();
  const [urlParams] = useState(new URLSearchParams(window.location.search));




  const [order, setOrder] = useState({ "value": "date_desc", "label": "Date Uploaded : Newest" });

  const location = useLocation();

  const [orderOptions] = useState([
    {
      "value": "date_desc",
      "label": "Date Uploaded : Newest"
    },
    {
      "value": "date_asc",
      "label": "Date Uploaded : Oldest"
    },
    {
      "value": "price_asc",
      "label": "Price : Low to High"
    },
    {
      "value": "price_desc",
      "label": "Price : High to Low"
    }
  ]);
  useEffect(() => {

    orderOptions.map((orderO) => {
      if (orderO.value === urlParams.get("order")) {
        setOrder(orderO);
      }
      return false;
    });

    axiosInstance
      .get("ajax")
      .then((res) => {
        //console.log(res.data);
        if (res.data.error !== undefined && res.data.error === false) {
          dispatch(setMakes(res.data.makes));
          setMakes(res.data.makes);
        }
      })
      .then()
      .catch(() => {
        
      });
  }, [dispatch,orderOptions, urlParams]);


  

  // console.log(getQueryVariable(search));



  return (
    <>
      <div className="wo-searchhead">
        <div className="wo-searchhead__title">
          <h2>Your Search Result</h2>
          <span>About {vehicles.length} search result found</span>
        </div>
        <div className="wo-searchhead__type">
          <span className="wo-searchtype">
            <i className="fas fa-filter"></i>
          </span>
          <div className="wo-select">

            <Select
              className="w-100 react-select-container"
              options={orderOptions}
              value={order}
              onChange={(e) => {
                setOrder(e);
                urlParams.set(
                  "order",
                  e.value
                );
                window.location.href =
                  location.pathname + "?" + urlParams.toString();
              }} />

          </div>
        </div>
      </div>
      <div className="wo-narrow-searchfilter wo-dnavbar">
        <div className="wo-filter-title">
          <h4>Narrow Your Search Here:</h4>
          <a id="wo-clearfilter" href="/vehicles">
            Clear All Filters
          </a>
         
        </div>
        <form action="">
         <ul className="wo-filter-section">

          <Vehicle makes={makes} />
          <li className="wo-searchftype">
            <Fuel fuel={searchData?.fuel} />
          </li>
          <li className="wo-searchftype">
            <Body body={searchData?.types} />
          </li>
          <li className="wo-searchftype">
            <Transmission transmission={searchData?.transmission} />
          </li>
          <li className="wo-searchftype">
            <Steering steering={searchData?.steering} />
          </li>
          <li className="wo-searchftype">
            <DriveTrain drive={searchData?.drive} />
          </li>
          <li className="wo-searchftype">
            <Color color={searchData?.color} />
          </li>
          <li className="wo-searchftype">
            <Assembly country={searchData?.countries} />
          </li>
          <li className="wo-searchftype">
            <MaxPrice />
          </li>
          </ul>
          <div className="text-right mt-1">
            <button
              type="submit"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "5px",
                paddingBottom: "5px",
                background: "#123652",
                color: "#fff",
                borderRadius: "4px",
              }}
            >
              <small>
                <i className="fa fa-search"></i>
              </small>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchHead;
