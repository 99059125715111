import { createSlice } from "@reduxjs/toolkit";
export const vehicleSlice = createSlice({
  name: "myvehicle",
  initialState: {
    value: localStorage.getItem("myvehicle")
      ? localStorage.getItem("myvehicle")
      : false,
  },
  reducers: {
    addVehicle: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      localStorage.setItem("myvehicle", action.payload);

      return state;
    },
    removeVehicle(state, action) {
      localStorage.setItem("myvehicle", false);

      return state;
    }
  },
});

// Action creators are generated for each case reducer function
export const { addVehicle, removeVehicle } = vehicleSlice.actions;

export default vehicleSlice.reducer;
