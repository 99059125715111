import React, { useState } from "react";
import axiosInstance from "../../helpers/axios";
import Select from "react-select";

function CarsSearch({ makes, settings }) {
  const [models, setModels] = useState([]);

  let minyear = new Date().getFullYear() - 60;
  let yearsoptions = [];
  for (let index = 1; index <= new Date().getFullYear() - minyear; index++) {
    yearsoptions.push({ value: minyear + index, label: minyear + index });
  }

  let minPrice = 500;
  let priceoptions = [];
  for (let i = 0; i < 20000; i = i + 500) {
    priceoptions.push({ value: minPrice + i, label: minPrice + i });
  }
  const [minyears] = useState(yearsoptions);
  const [maxyears, setMaxYears] = useState([]);

  function getMaxYears(minYear) {
    let maxoptions = [];

    for (let index = 1; index <= new Date().getFullYear() - minyear; index++) {
      if (minyear + index > minYear)
        maxoptions.push({ value: minyear + index, label: minyear + index });
    }

    setMaxYears(maxoptions);
  }

  function getModels(e) {
    axiosInstance
      .get(`ajax/models/${e}`)
      .then((res) => {
        setModels(res.data);
      })
      .then();
  }

  return (
    <>
      <div className="wo-tabtitle">
        <h4>
          <span>{settings?.vehiclesTop}</span>{settings?.vehiclesBottom}
        </h4>
      </div>
      <form action="/vehicles" className="wo-themeform wo-bannerform mt-3">
        <fieldset>
          <div className="form-group mb-2">
            <label className="wo-titleinput">I’m Looking For:</label>
            <Select
              className="w-100 react-select-container"
              placeholder="Vehicle Make"
              isSearchable
              required
              name="make"
              onChange={(e) => {
                if (e.slug) {
                  getModels(e.slug);
                }
              }}
              options={makes}
            />
          </div>
          <div className="form-group mb-2">
            <label className="wo-titleinput">Vehicle Model:</label>
            <Select
              className="w-100 react-select-container"
              placeholder="Vehicle Model"
              isSearchable
              required
              name="model"
              isClearable
              options={models}
            />
          </div>
          <div className="form-group mb-2">
            <label className="wo-titleinput">Vehicle Year Range:</label>
            <div className="wo-rangeselect">
              <div className="wo-select">
                <Select
                  className="w-100 react-select-container mr-1"
                  placeholder="Min Year"
                  isSearchable
                  name="year_from"
                  isClearable
                  onChange={(e) => {
                    if (!e) {
                      return;
                    }
                    if (e.value) {
                      getMaxYears(e.value);
                    }
                  }}
                  options={minyears}
                />
              </div>
              <div className="wo-select">
                <Select
                  className="w-100 react-select-container"
                  placeholder="Max Year"
                  isSearchable
                  name="year_to"
                  isClearable
                  options={maxyears}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-2 group-ranges">
            <label className="wo-titleinput">Vehicle Price Range:</label>
            <div className="wo-rangeselect">
              <div className="wo-select">
                <Select
                  className="w-100 react-select-container mr-1"
                  placeholder="Min Price"
                  isSearchable
                  name="min_price"
                  isClearable
                  options={priceoptions}
                />
              </div>
              <div className="wo-select">
                <Select
                  className="w-100 react-select-container"
                  placeholder="Max Price"
                  isSearchable
                  name="max_price"
                  isClearable
                  options={priceoptions}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-2 wo-form-btns">
            <button type="submit" className="wo-btn">
              Browse Cars
            </button>
          </div>
        </fieldset>
      
      </form>
    </>
  );
}

export default CarsSearch;
