import React from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import CarsSearch from "./CarsSearch";
import PartsSearch from "./PartsSearch";

function HeroSearch({ loading, makes, settings }) {
  if (loading) {
    return (
      <Center className="wo-banner__formwrap">
        <Spinner size="xl" color="blue.300" />
      </Center>
    );
  }

  return (
    <Tabs
      isManual
      variant="enclosed"
      className="wo-banner__formwrap col-sm-push-8"
      isLazy
      isFitted
    >
      <TabList className="wo-bannertabs-nav">
        <Tab
          _selected={{
            color: "blue.500",
            bg: "transparent",
            // padding: "15px",
            // paddingLeft: "30px",
            // paddingRight: "30px",
            // fontSize: "sm",
          }}
        >
          Cars
        </Tab>
        <Tab
          className="nav-item"
          bg="primary"
          _selected={{
            color: "blue.500",
            bg: "transparent",

            // padding: "15px",
            // paddingLeft: "30px",
            // paddingRight: "30px",
            // fontSize: "sm",
          }}
        >
          Parts
        </Tab>
        {/* <Tab
          _selected={{
            color: "blue.500",
            bg: "transparent",
            // padding: "15px",
            // paddingLeft: "30px",
            // paddingRight: "30px",
            // fontSize: "sm",
          }}
        >
          Car Breaking
        </Tab> */}
      </TabList>
      <TabPanels>
        <TabPanel>
          <CarsSearch settings={settings} makes={makes} />
        </TabPanel>
        <TabPanel>
          <PartsSearch settings={settings}  makes={makes} state={1} />
        </TabPanel>
        {/* <TabPanel>
          <PartsSearch makes={makes} state={0} />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
}

export default HeroSearch;
