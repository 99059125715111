import React from "react";

function Toast() {
  //   useEffect(() => {

  //   });
  return <></>;
}

export default Toast;
