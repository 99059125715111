import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PartSlider({ part }) {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  if (part.images.length < 1) {
    return <></>;
  }
  return (
    <div className="wo-vsingleslider card">
      <Slider {...settings}>
        {part.images.map((image, index) => (
          <img alt="part" src={part.image_base + image} key={index} />
        ))}
      </Slider>
    </div>
  );
}

export default PartSlider;
