import React, {useState,useEffect} from 'react'
import Header from '../components/header/Header'
import {Spinner, Center, toast} from "@chakra-ui/react";
import {useSelector,useDispatch} from "react-redux";
import { addVehicle, removeVehicle } from "../helpers/redux/myVehicleSlice";
import { useFormik } from "formik";
import {  Box, CloseButton, Flex, Spacer } from "@chakra-ui/react";
import axiosInstance from '../helpers/axios';
import Select from "react-select";


function MyVehicles() {

    const [loading, setLoading] = useState(true);
    const myvehicles = useSelector((state) => state.myvehicles.value);

    const [makes, setMakes] = useState([]);

    const [variations, setVariations] = useState([]);
    const [engines, setEngines] = useState([]);
    const dispatch = useDispatch();

    const [make, setMake] = useState();
    const [variation, setVariation] = useState();
    const [engine, setEngine] = useState();

  const validate = (values) => {
  const errors = {};




    if (!values.make || values.make === "null") {
      errors.make = "Please select make";
    }

    if (!values.variation || values.variation === "null") {
      errors.variation = "Please select model variation";
    }

    if (!values.engine || values.engine === "null") {
        errors.engine = "Please select engine";
    }

    if(Object.keys(errors).length > 0){
        toast({
            description: "Please select all fields",
            position: "top-right",
            status: "error",
        });
    }

    return errors;
  };

    useEffect(() => {
        
        setLoading(true);

        axiosInstance
        .get("ajax")
        .then((res) => {
                //console.log(res.data);
                if (res.data.error !== undefined && res.data.error === false) {
                    setMakes(res.data.makes)
                }
                setLoading(false);
        })
        .then()
        .catch(() => {
                setLoading(false);
        });

    }, []);


    const formik = useFormik({
        initialValues: {
          make: make,
          variation: variation,
          engine: engine
        },
        validate,
        onSubmit: (value) => {
          
            const data = {
                make: make,
                variation:variation,
                engine:engine
            };

            dispatch(addVehicle(data));

            toast({
                description: "Vehicle list updated!",
                position: "top-right",
                status: "success",
            });
          //window.location.reload();
        },
      });
    
    function getModels(e) {
        axiosInstance
          .get(`ajax/variations/${e}`)
          .then((res) => {
            setVariations(res.data);
          })
          .then();
    }
    
    function getEngines(e) {
        axiosInstance
          .get(`ajax/engines/${e}`)
          .then((res) => {
            setEngines(res.data);
          })
          .then();
    }
    
    
    return (
        <>
            <Header  type="parts"/>
            <main className="wo-main">
                <div className="wo-main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                            <h5>My Vehicles</h5>
                            
                             {myvehicles.map((myvehicle, index)=>(
                              <Box maxW="sm" borderWidth="1px" key={index} borderRadius="lg" overflow="hidden">
                        
                              <Box p="6">
                                
                        
                                <Box
                                  mt="1"
                                  fontWeight="semibold"
                                  as="h5"
                                  lineHeight="tight"
                                  isTruncated
                                >
                                    <Flex>{myvehicle.make.name} <Spacer />  <CloseButton  onClick={()=>{
                                        dispatch(removeVehicle(myvehicle));
                                    }} size="md" /></Flex>
                                  
                                </Box>
                        
                                <Box>
                                  {myvehicle.variation.label}
                                  <Box as="span" color="gray.600" fontSize="sm">
                                  {myvehicle.engine.label}
                                  </Box>
                                </Box>
                        
                                
                              </Box>
                            </Box>
                             ))}

                            </div>
                            <div className="col-md-6">
                                <h5>Add New Vehicle</h5>
                                {loading && <Center>
                                    <span className="mt-5 pt-5">
                                      <Spinner size="xl" color="blue.300" />
                                    </span>
                                </Center>}
                                {!loading && (
                                    <form
                                    className="wo-themeform wo-bannerform mt-3"
                                    onSubmit={formik.handleSubmit}
                                  >
                                    <fieldset>
                                      <div className="form-group mb-2">
                                        <label className="wo-titleinput">Vehicle Make:</label>
                                        <Select
                                          className="w-100 react-select-container"
                                          placeholder="Vehicle Make"
                                          isSearchable
                                          required
                                          name="make"
                                          value={make}
                                          isClearable
                                          options={makes}
                                          onChange={(e) => {
                                            console.log(e);
                                            if (e) {
                                              getModels(e.value);
                                              formik.values.make = e.value;
                                              setMake(e);
                                            } else {
                                              setMake(false);
                                              setVariation(false);
                                              setEngine(false);
                                              formik.values.engine = "";
                                              formik.values.variation = "";
                                              formik.values.make = "";
                                            }
                            
                                            // if (e.id) {
                                            //   getModels(e.id);
                                            // }
                                          }}
                                        />
                                      </div>
                                      <div className="form-group mb-2">
                                        <label htmlFor="" className="wo-titleinput">
                                          Vehicle Variation
                                        </label>
                                        <Select
                                          className="w-100 react-select-container"
                                          placeholder="Vehicle Variation"
                                          isSearchable
                                          name="model"
                                          isClearable
                                          value={variation}
                                          onChange={(e) => {
                                            if (e) {
                                              getEngines(e.value);
                                              formik.values.variation = e.value;
                                              setVariation(e);
                                            } else {
                                              setVariation(false);
                                              setEngine(false);
                                              setEngines([]);
                                              formik.values.engine = "";
                                              formik.values.variation = "";
                                            }
                            
                                            // if (e.id) {
                                            //   getModels(e.id);
                                            // }
                                          }}
                                          // onChange={(e) => {
                                          //   if (!e) {
                                          //     return;
                                          //   }
                                          //   if (e.value) {
                                          //     getMaxYears(e.value);
                                          //   }
                                          // }}
                                          options={variations}
                                        />
                                      </div>
                            
                                      <div className="form-group mb-2">
                                        <label htmlFor="" className="wo-titleinput">
                                          Vehicle Engine
                                        </label>
                                        <Select
                                          className="w-100 react-select-container"
                                          placeholder="Vehicle Engine"
                                          isSearchable
                                          name="engine"
                                          isClearable
                                          value={engine}
                                          options={engines}
                                          onChange={(e) => {
                                            if (e) {
                                              formik.values.engine = e.value;
                                              setEngine(e);
                                            } else {
                                              setEngine(false);
                                              formik.values.engine = "";
                                              setEngine([]);
                                            }
                            
                                            // if (e.id) {
                                            //   getModels(e.id);
                                            // }
                                          }}
                                        />
                                      </div>
                                      {/* <input name="state" value={state ? "new" : "used"} hidden /> */}
                                      <div className="form-group mb-2 wo-form-btns">
                                        <button type="submit" className="wo-btn">
                                          Add New Vehicle
                                        </button>
                                      </div>
                                    </fieldset>
                                  </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default MyVehicles
