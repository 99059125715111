import React, { useState, useEffect } from 'react';
import Select from 'react-select';


function Assembly({country}) {
    const [option, setOption] = useState();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));
  


    useEffect(() => {

        if(country){
            let countryOptions = [];
            country.map((c)=>{
                countryOptions.push({ value:c.Code, label: c.Name });
                if (urlParams.get('assembly') === c.Code) {
                    setOption(countryOptions[countryOptions.length - 1]);
                }
                return c;
            });
            setOptions(countryOptions);
            setLoading(false);
        }
        



    }, [country, urlParams]);
    return (
        <Select
            className="w-100 react-select-container mr-1"
            placeholder="Assemble Country"
            isSearchable
            name="assembly"
            value={option}
            isClearable
            isLoading={loading}
            options={options}
            onChange={(e) => {
                if (!e) {
                    urlParams.delete("assembly");
                    //urlParams.set("page",1);
                    setOption(null);
                } else {
                    setOption(e);
                    urlParams.set("assembly", e.value);
                }
    
                
            }}
            />)
        
    
}

export default Assembly
