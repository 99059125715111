import React from "react";
import { Container } from "@chakra-ui/react";
import Header from "../components/header/Header";
import HomeHero from "../components/hero/HomeHero";
import SectionHead from "../components/basic/SectionHead";
import CategoriesGrid from "../components/categories/CategoriesGrid";

function Home() {
  const sectiondata = {
    top: "Exprience the motion",
    heading: ["Vehicle ", <em key="787">Parts</em>],
    paragraph: `The vehicle parts and everything you may need for repairs and regular maintenance of your vehicle are listed in a convenient and comprehensive catalogue.`,
  };
  return (
    <>
      <Header />
      <HomeHero />
      <Container maxW="container.lg">
        <SectionHead
          top={sectiondata.top}
          heading={sectiondata.heading}
          paragraph={sectiondata.paragraph}
        />
        <CategoriesGrid />
      </Container>
    </>
  );
}

export default Home;
