import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import { useAuth } from "../helpers/google/AuthContext";
import { Center, Spinner } from "@chakra-ui/react";

function ResetPassword() {
  const { reset } = useAuth();
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isloading, setIsLoading] = useState(false);

  async function handleReset(value) {
    try {
      setError("");
      setIsLoading(true);
      await reset(value.email);
      setIsLoading(false);
      setMessage("Please check your email to reset your password");
    } catch {
      setError("Failed to reset password");
    }

    setIsLoading(false);
  }

  const validate = (values) => {
    const errors = {};

    if (values.email.length < 2) {
      errors.email = "Email field is required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validate,
    onSubmit: (value) => {
      console.log(value);
      handleReset(value);
    },
  });

  return (
    <>
      <Header type="vehicles" />
      <div>
        <div className="wo-innerbannertwo-wrap bg-color2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="wo-innerbannertwo">
                  <div className="wo-innerbannertwo__title sidebar">
                    <h4>
                      <span>We’re happy to see you back</span>Login To Your
                      Account Now
                    </h4>
                  </div>
                  <div className="wo-innerbannertwo__description">
                    <p>
                     
                    </p>
                  </div>
                  <div className="wo-innerbannertwo__btns">
                    <span className="mr-5">Already have an account ? </span>
                    <Link to="/signup" className="wo-btn">
                      Signup
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="wo-innerbannertwo-img">
                  <img
                    src="images/inner-banner/img-04.png"
                    alt="img description"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div id="particles-js" className="wo-particles particles-js" />
        </div>
        <main className="wo-main overflow-hidden">
          <section className="wo-main-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="wo-signupwrap">
                    <figure className="wo-signupwrap__img">
                      <img src="images/signup-img.jpg" alt="img description" />
                    </figure>
                    <div className="wo-signup">
                      <div className="wo-signup__title">
                        <h3>Login</h3>
                      </div>
                      <form
                        className="wo-themeform wo-signup-form"
                        onSubmit={formik.handleSubmit}
                      >
                        {error && (
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Error!</strong> {error}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {message && (
                          <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Success! </strong> {message}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {isloading && (
                          <Center className="wo-banner__formwrap w-100 h-100">
                            <Spinner size="xl" color="blue.300" />
                          </Center>
                        )}
                        <fieldset>
                          <div className="form-group">
                            <label className="wo-titleinput">Your Email:</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                formik.values.email = e.target.value;
                                setEmail(e.target.value);
                              }}
                              placeholder="Enter email*"
                            />
                          </div>
                          <p className="small text-danger">
                            {formik.errors.email}
                          </p>

                          <div className="form-group wo-form-btn"></div>
                          <div className="form-group wo-form-btn">
                            <div>
                              <label htmlFor="termsa">
                                <span style={{ color: "#999" }}>
                                  Rember your password ?
                                  <Link
                                    className="ml-2"
                                    to="/login"
                                    isabled={isloading}
                                  >
                                    Login
                                  </Link>
                                </span>
                              </label>
                            </div>
                            <button
                              disabled={isloading}
                              type="submit"
                              className="wo-btn"
                            >
                              Reset Now
                            </button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default ResetPassword;
