import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton, Stack, Center } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axiosInstance from "../../helpers/axios";
import { setVehicles } from "../../helpers/redux/carsSlice";
import VehicleListItem from "./VehicleListItem";

function CarsList({ page, setPage }) {

  const [pages] = useState(1);

  const vehicles = useSelector((state) => state.vehicles.value);
  const [loading, setLoading] = useState(true);
  const [search] = useState(new URLSearchParams(window.location.search));
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {

    setPage(1);
    axiosInstance
      .get("ajax/search/"+page+ "?" + search.toString())
      .then((res) => {
        //setIsLoading(false);
        setLoading(false);
        if (res.data.results) {
          //setPages(res.data.pages);
          dispatch(setVehicles(res.data.results));
        }
        //setMakes(res.data);
      })
      .then();
  }, [pages, dispatch, page, search, setPage]);

  function loadmore(data) {
    let selected = data.selected;
    search.set('page', selected + 1);
    setLoading(true);
    window.location.href = location.pathname + "?" + search.toString();
    return;

    // dispatch(setVehicles([]));
    // axiosInstance
    //   .get(`ajax/search/${selected + 1}` + "?" + search.toString())
    //   .then((res) => {
    //     setLoading(false);

    //     if (res.data.results) {
    //       setPages(res.data.pages);
    //       dispatch(setVehicles(res.data.results));
    //     }
    //     //setMakes(res.data);
    //   })
    //   .then();
  }
  if (loading) {
    return (
      <Stack>
        <div className="wo-vehicleslist">
          <div className="mt-3">
            <div className="row p-2 ">
              <div className="col-lg-4 col-md-6 pr-0 mb-1">
                <Skeleton w="100%" height="150px">
                  This is the Box
                </Skeleton>
              </div>
              <div className="col-lg-8 col-md-6">
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="30%"
                  height="20px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="100%"
                  height="40px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="40%"
                  height="20px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="100%"
                  height="40px"
                >
                  This is the Box
                </Skeleton>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="row p-2 ">
              <div className="col-lg-4 col-md-6 pr-0 mb-1">
                <Skeleton w="100%" height="150px">
                  This is the Box
                </Skeleton>
              </div>
              <div className="col-lg-8 col-md-6">
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="30%"
                  height="20px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="100%"
                  height="40px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="40%"
                  height="20px"
                >
                  This is the Box
                </Skeleton>
                <Skeleton
                  className="wo-vehicles__title mb-2"
                  w="100%"
                  height="40px"
                >
                  This is the Box
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    );
  }
  return (
    <div className="wo-vehicleslist">
          {vehicles.length === 0 && ( <Center className="wo-banner__formwrap text-center w-100 mt-5 mb-5">
               
               <h3><i className="far fa-folder-open mr-2"></i>No Vehicles Found</h3>
       </Center>)}
      {vehicles.map((vehicle) => (
        <VehicleListItem key={vehicle.id} vehicle={vehicle} />
      ))}
      <nav className="wo-pagination">
        {pages > 1 && (
          <ReactPaginate
            pageCount={pages}
            previousLinkClassName="wo-prevpage"
            nextLinkClassName="wo-nextpage"
            activeClassName={"wo-active"}
            previousLabel={"PRV"}
            nextLabel={"NEX"}
            onPageChange={loadmore}
          />
        )}
      </nav>
    </div>
  );
}

export default CarsList;
