import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import { useAuth } from "../helpers/google/AuthContext";
import { Center, Spinner, useToast } from "@chakra-ui/react";

function SignUp() {
  const { signup, changeName, googlesign } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const history = useHistory();

  const toast = useToast();

  async function handleSignUp(value) {
    try {
      setError("");
      setIsLoading(true);
      await signup(value.email, value.password);
      await changeName(value.name);
      history.push("/");
    } catch (err) {
      toast({
        description: "Failed to created account. " + err.message,
        status: "error",
        position: "top-right",
      });
    }

    setIsLoading(false);
  }

  async function handleGoogleSignUp() {
    try {
      setError("");
      setIsLoading(true);
      await googlesign();
      history.push("/");
    } catch (err) {
      toast({
        description: "Failed to created account. " + err.message,
        status: "error",
        position: "top-right",
      });
    }
    setIsLoading(false);
  }

  const validate = (values) => {
    const errors = {};

    if (values.name.length < 6) {
      errors.name = "Name field is required min(6 characters)";
    }

    if (values.email.length < 2) {
      errors.email = "Email field is required";
    }

    if (values.password.length < 6) {
      errors.password = "Password field is required min(6 characters)";
      setPassword("");
      setRepeatPassword("");
    }

    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords do not match !";
      setPassword("");
      setRepeatPassword("");
    }

    // if (!token) {
    //   errors.repeatPassword = "Failed to register try again later";
    //   toast({
    //     description: "Failed to register try again later",
    //     status: "error",
    //     position: "top-right",
    //   });
    //   setPassword("");
    //   setRepeatPassword("");
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      password: password,
      repeatPassword: repeatPassword,
    },
    validate,
    onSubmit: (value) => {
      console.log(value);
      handleSignUp(value);
    },
  });

  return (
    <>
      <Header type="vehicles" />
      {/* <GoogleReCaptcha
         onVerify={(token) => {
           setToken(token);
         }}
       /> */}
      <div>
        <div className="wo-innerbannertwo-wrap bg-color2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="wo-innerbannertwo">
                  <div className="wo-innerbannertwo__title sidebar">
                    <h4>
                      <span>We’re Growing Every Day</span>Join Our Community Now
                    </h4>
                  </div>
                  <div className="wo-innerbannertwo__description">
                    <p>
                    
                    </p>
                  </div>
                  <div className="wo-innerbannertwo__btns">
                    <span className="mr-5">Already have an account ? </span>
                    <Link to="/login" className="wo-btn">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="wo-innerbannertwo-img">
                  <img
                    src="images/inner-banner/img-04.png"
                    alt="img description"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div id="particles-js" className="wo-particles particles-js" />
        </div>
        <main className="wo-main overflow-hidden">
          <section className="wo-main-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="wo-signupwrap">
                    <figure className="wo-signupwrap__img">
                      <img src="images/signup-img.jpg" alt="img description" />
                    </figure>
                    <div className="wo-signup" style={{ width: "minContent" }}>
                      <div className="wo-signup__title">
                        <h3>Signup Free Today</h3>
                      </div>
                      <form
                        className="wo-themeform wo-signup-form"
                        onSubmit={formik.handleSubmit}
                      >
                        {error && (
                          <div
                            style={{ display: "inlineGrid" }}
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Error!</strong> {error}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {isloading && (
                          <Center className="wo-banner__formwrap w-100 h-100">
                            <Spinner size="xl" color="blue.300" />
                          </Center>
                        )}
                        <fieldset>
                          <div className="form-group">
                            <label className="wo-titleinput">
                              Your Full Name:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={name}
                              onChange={(e) => {
                                formik.values.name = e.target.value;
                                setName(e.target.value);
                              }}
                              placeholder="Enter Full Name*"
                            />
                          </div>
                          <p className="small text-danger">
                            {formik.errors.name}
                          </p>
                          <div className="form-group">
                            <label className="wo-titleinput">
                              Your Email ID:
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                formik.values.email = e.target.value;
                                setEmail(e.target.value);
                              }}
                              placeholder="Enter email*"
                            />
                          </div>
                          <p className="small text-danger">
                            {formik.errors.email}
                          </p>
                          <div className="form-group wt-eyeicon">
                            <label className="wo-titleinput">
                              Your Password:
                            </label>
                            <input
                              type="password"
                              id="typePassa"
                              className="form-control"
                              name="password"
                              value={password}
                              placeholder="Enter password*"
                              onChange={(e) => {
                                formik.values.password = e.target.value;
                                setPassword(e.target.value);
                              }}
                              required
                            />
                            <Link
                              to="#"
                              onClick={() => {
                                var x = document.querySelector("#typePassa");
                                if (x.type === "password") {
                                  x.type = "text";
                                } else {
                                  x.type = "password";
                                }
                              }}
                            >
                              <i className="fas fa-eye" />
                            </Link>
                          </div>
                          <p className="small text-danger">
                            {formik.errors.password}
                          </p>
                          <div className="form-group wt-eyeicon">
                            <label className="wo-titleinput">
                              Retype Password:
                            </label>
                            <input
                              type="password"
                              id="retypepass"
                              className="form-control"
                              name="repeatPassword"
                              value={repeatPassword}
                              onChange={(e) => {
                                formik.values.repeatPassword = e.target.value;
                                setRepeatPassword(e.target.value);
                              }}
                              placeholder="Retype password*"
                              required
                            />
                            <Link
                              to="#"
                              onClick={() => {
                                var x = document.querySelector("#retypepass");
                                if (x.type === "password") {
                                  x.type = "text";
                                } else {
                                  x.type = "password";
                                }
                              }}
                            >
                              <i className="fas fa-eye" />
                            </Link>
                          </div>
                          <p className="small text-danger">
                            {formik.errors.repeatPassword}
                          </p>
                          <div className="form-group wo-form-btn">
                            <div>
                              <label htmlFor="termsa">
                                <span style={{ color: "#999" }}>
                                  By signing up you agree to our
                                  <Link className="ml-2" to="#">
                                    Terms &amp; Conditions
                                  </Link>
                                </span>
                              </label>
                            </div>
                            <button
                              disabled={isloading}
                              type="submit"
                              className="wo-btn"
                            >
                              Sign Up Now
                            </button>
                          </div>
                        </fieldset>
                      </form>
                      <div className="wo-signupfooter">
                        <span className="wo-optionsbar">
                          <em>or</em>
                        </span>
                        <div className="wo-loginicon">
                          <label className="wo-titleinput">
                            Signup Via Social Network:
                          </label>
                          <ul>
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  handleGoogleSignUp();
                                }}
                                className="wo-googlebox"
                              >
                                <span>
                                  <i className="fab fa-google" />
                                </span>
                                Via google
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default SignUp;
