import React from "react";
// import Slider from "react-slick";

function SmallCarousel({ vehicle, images }) {
  // const settings = {
  //   dots: false,

  //   infinite: true,
  //   autoPlay: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  if (images.length < 1) {
    return <></>;
  }
  return <img src={vehicle.image_base + images[0]} alt="" />;
}

export default SmallCarousel;
