import { extendTheme } from "@chakra-ui/react";
// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

// const Button = {
//   // style object for base or default style
//   baseStyle: {},
//   // styles for different sizes ("sm", "md", "lg")
//   sizes: {},
//   // styles for different visual variants ("outline", "solid")
//   variants: {
//     primary: {
//       bg: "primary",
//     },
//   },
//   // default values for `size` and `variant`
//   defaultProps: { variant: "primary" },
// };
const theme = extendTheme({
  colors,
});

export default theme;
