import React from "react";
import PartsSidebar from "../components/categories/PartsSidebar";
import Header from "../components/header/Header";
import PartsList from "../components/parts/PartsList";

function PartsPage() {


  return (
    <>
      <Header type="parts" />
    
      <main className="wo-main overflow-hidden">
        
        <div className="wo-main-section pt-0">
          <div className="row">
           
            <div className="col-12">
              <div className="nav-icon4" id="nav-icon4" onClick={window['togglenav']}>
                
                <button className="btn btn-secondary btn-sm ml-2"> <i className="fas fa-bars"></i> Menu</button>
                
              </div>
             
              <div className="p-2 mt-4">
                <div className="row">
                  <div className="col-md-3">
                    <PartsSidebar />
                  </div>
                  <div className="col-md-9">
                    
                    <PartsList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PartsPage;
