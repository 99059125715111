import React from "react";

function VehicleBasicUl({ vehicle }) {
  return (
    <ul className="wo-vehicles__list">
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={process.env.PUBLIC_URL + "/images/theme-icon/img-01.png"}
              alt="img description"
            />
          </i>
          {vehicle.cc}CC
        </span>
      </li>
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={process.env.PUBLIC_URL + "/images/theme-icon/img-02.png"}
              alt="img description"
            />
          </i>
          {vehicle.transmission}
        </span>
      </li>
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={process.env.PUBLIC_URL + "/images/theme-icon/img-03.png"}
              alt="img description"
            />
          </i>
          {vehicle.fuel}
        </span>
      </li>
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={process.env.PUBLIC_URL + "/images/theme-icon/img-04.png"}
              alt="img description"
            />
          </i>
          {vehicle.drivetrain}
        </span>
      </li>
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={process.env.PUBLIC_URL + "/images/theme-icon/pantone.png"}
              alt="img description"
            />
          </i>
          {vehicle.color}
        </span>
      </li>
      <li>
        <span>
          <i className="wo-themeicon">
            <img
              src={
                process.env.PUBLIC_URL + "/images/theme-icon/steering-wheel.png"
              }
              alt="img description"
            />
          </i>
          {vehicle.steering}
        </span>
      </li>
    </ul>
  );
}

export default VehicleBasicUl;
