import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function MaxPrice() {
    const [option, setOption] = useState();
    const [options] = useState([
        {
            "value" : "1000",
            "label" : "1,000"
        },
        {
            "value" : "2000",
            "label" : "2,000"
        },
        {
            "value" : "3000",
            "label" : "3,000"
        },
        {
            "value" : "4000",
            "label" : "4,000"
        },
        {
            "value" : "5000",
            "label" : "5,000"
        },
        {
            "value" : "10000",
            "label" : "10,000"
        },
        {
            "value" : "20000",
            "label" : "20,000"
        },
        {
            "value" : "50000",
            "label" : "50,000"
        },
        {
            "value" : "100000",
            "label" : "100,000"
        },
        {
            "value" : "300000",
            "label" : "300,000"
        }
    ]);


    const [urlParams] = useState(new URLSearchParams(window.location.search));



    useEffect(() => {
        options.map((o) => {
            if(urlParams.get('max_price') === o.value){
                setOption(o);
            }
            return o;
        });

    }, [options, urlParams]);
    return (
        <Select
        className="w-100 react-select-container mr-1"
        placeholder="Max Price"
        isSearchable
        name="max_price"
        value={option}
        isClearable
        options={options}
        onChange={(e) => {
            if (!e) {
                urlParams.delete("max_price");
                //urlParams.set("page",1);
                setOption(null);
            } else {
                setOption(e);
                urlParams.set("max_price", e.value);
            }
        }}
        />
    )
}

export default MaxPrice
