import React from "react";
import { Link } from "react-router-dom";
import SmallCarousel from "./SmallCarousel";
import VehicleBasicUl from "./VehicleBasicUl";

function VehicleListItem({ vehicle }) {
  function numwithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="card mt-3">
      <div className="row p-2 ">
        <div className="col-lg-4 col-md-6 pr-0">
          <SmallCarousel vehicle={vehicle} images={vehicle.images} />
          <div className="wo-vehiclesimg-tags">
            <div className="wo-tags">
              {vehicle.featured === true && (
                <Link to="#" className="wo-tag-featured">
                  Featured
                </Link>
              )}
              {vehicle.negotiable === true && (
                <Link to="#" className="wo-tag-rent">
                  NEG
                </Link>
              )}

              <Link to="#" className="wo-tag-photos">
                <i className="fas fa-image" />
                {vehicle.images.length}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-6">
          <div className="wo-vehicles">
            <div className="wo-vehicles-head">
              <div className="wo-vehicles__tags">
                <Link to="#">{vehicle.body}</Link>
              </div>
              <div className="wo-vehicles__title">
                <h5>
                  <Link to={`/vehicle/${vehicle.id}`}>
                    {vehicle.year} {vehicle.make} {vehicle.model}
                  </Link>
                  <span>
                    <sup>$</sup>
                    {numwithCommas(vehicle.price)}
                    <em>{vehicle.negotiable && ` - Negotiable`}</em>
                  </span>
                </h5>
              </div>
            </div>
            <VehicleBasicUl vehicle={vehicle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleListItem;
