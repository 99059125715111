import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import { Center, Spinner } from "@chakra-ui/react";
import PartSlider from "../components/parts/PartSlider";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../helpers/axios";
import PartDetails from "../components/parts/PartDetails";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

function PartPage() {
  const [loading, setLoading] = useState(true);
  const [part, setPart] = useState();

  const { uid } = useParams();

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("ajax/part/" + uid)
      .then((res) => {
        setLoading(false);
        // setVariation(res.data.result.variations[0]);
        setPart(res.data.result);
        // setPrice(res.data.result.variations[0].variation_price);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [uid]);

  return (
    <>
      <div>
        <Header type="part" />

        <main className="wo-main overflow-hidden">
          <section className="wo-main-section">
            <div className="container">
              <div className="">
                {loading && (
                  <Center className="wo-banner__formwrap w-100 h-100">
                    <Spinner size="xl" color="orange.300" />
                  </Center>
                )}
                {!loading && part && (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <PartSlider part={part} />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <PartDetails part={part} />
                      </div>
                    </div>
                    <div className="row">
                      <Tabs className="w-100" size="md" variant="enclosed">
                        <TabList>
                          <Tab className="mr-1">
                            <small>Description</small>
                          </Tab>
                          <Tab className="mr-1">
                            <small>Manufacturer</small>
                          </Tab>
                          <Tab className="mr-1">
                            <small>Supported Vehicles</small>
                          </Tab>
                        </TabList>
                        <TabPanels>
                          <TabPanel>
                            <p>{part.description}</p>
                          </TabPanel>
                          <TabPanel>
                            <div className="wo-usersrate w-100 d-flex justify-center space-between">
                              <img
                                className="mr-2 img-fluid"
                                src={part.brand.logo}
                                alt=""
                                width="100px"
                              />
                              <div className="d-flex flex-grow">
                                <div className="d-block">
                                  <span>Manufacturer</span>
                                  <h5> {part.brand.name}</h5>
                                  <p>{part.brand.details}</p>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <ul>
                              {part.vehicles.map((vehicle, index) => (
                                <li> {vehicle.make}  {vehicle.variation}, {vehicle.engine}</li>
                              ))}
                            </ul>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </div>

                    <div className="mt-4">
                      {part.simillar.length > 0 && <h5>You might also like</h5>}

                      <div className="wo-spareparts-grid wo-spareparts-classic">
                        {part.simillar.map((item) => (
                          <div className="wo-col-grid" key={item.id}>
                            <div className="wo-searchpart">
                              <figure className="wo-searchpart__img">
                                {item.images.length > 0 && (
                                  <img
                                    src={`${item.image_base}${item.images[0]}`}
                                    alt="img description"
                                  />
                                )}
                              </figure>
                              <div className="wo-searchpart__content">
                                <div className="wo-articles-tags">
                                  <span>
                                    {item.category.name} /{" "}
                                    {item.category.subcategory.name}
                                  </span>
                                </div>
                                <div className="wo-searchpart-title">
                                  <h3>
                                    <a href="spare-part-single.html">
                                      {item.name}
                                    </a>
                                  </h3>
                                  <span>
                                    <sup>$</sup>
                                    {item.variations[0].variation_price}
                                    {item.shipping && <em>(Free Shipping)</em>}
                                  </span>
                                </div>
                                <div className="wo-searchpart-btns">
                                  <Link
                                    to={`/part/${item.id}`}
                                    className="wo-btn w-100"
                                  >
                                    Details
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default PartPage;
