import React from "react";
import { Link } from "react-router-dom";

function VehicleAdvert({ vehicle }) {
  const images = JSON.parse(vehicle.vehicle_images);

  function numwithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <figure className="h-100">
      {images.length > 0 && (
        <img
          src={vehicle.image_base + images[0]}
          alt={vehicle.vehicle_name}
          className="w-100 h-100"
        />
      )}
      <figcaption className="wo-slidercontent">
        <span className="wo-slidercontent__title">
          <span className="wo-tags">
            {vehicle.vehicle_featured && (
              <Link to="#" className="wo-tag-featured">
                Featured
              </Link>
            )}

            <Link to="#" className="wo-tag-rent">
              {vehicle.vehicle_body}
            </Link>
          </span>
          <h1>
            <Link to={`vehicle/` + vehicle.vehicle_uid}>
              {vehicle.make.make_name} {vehicle.model.model_name}
            </Link>
            <span>
              <sup>US $</sup>
              {numwithCommas(vehicle.vehicle_price)}
              {/* <em>
                Cash <br />
                Installments
              </em> */}
            </span>
          </h1>
        </span>
        <ul className="wo-slidercontent__featured d-none d-md-flex">
          <li>
            <img
              src="/images/index/featured/img-01.png"
              alt="img description"
            />
            <h4>{vehicle.vehicle_year}</h4>
            <span>Model Year</span>
          </li>
          <li>
            <img
              src="/images/index/featured/img-02.png"
              alt="img description"
            />
            <h4>{numwithCommas(vehicle.vehicle_distance)} km</h4>
            <span>Total Drived</span>
          </li>
          <li>
            <img
              src="/images/index/featured/img-03.png"
              alt="img description"
            />
            <h4>{vehicle.vehicle_fuel}</h4>
            <span>Fuel Type</span>
          </li>
          <li>
            <img
              src="/images/index/featured/img-04.png"
              alt="img description"
            />
            <h4>{vehicle.vehicle_transmission}</h4>
            <span>Transmission</span>
          </li>
        </ul>
      </figcaption>
    </figure>
  );
}

export default VehicleAdvert;
