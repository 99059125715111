import React, { useState, useEffect } from 'react'
import Select from "react-select";


function Fuel({ fuel }) {


    const [options, setOptions] = useState([]);
    const [fuelOption, setFuel] = useState();
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));


    useEffect(() => {

        if(fuel){
            let fuelarray = Object.entries(fuel);
            let fuelOptions = [];
            fuelarray.map((arr) => {
                fuelOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('fuel') === arr[0]) {
                    setFuel(fuelOptions[fuelOptions.length - 1]);
                }
                return arr;
            });
            setOptions(fuelOptions);
            setLoading(false);
        }

    }, [fuel,urlParams])


    return (
        <Select
            className="w-100 react-select-container mr-1"
            placeholder="Fuel Type"
            isSearchable
            isLoading={loading}
            name="fuel"
            value={fuelOption}
            isClearable
            options={options}
            onChange={(e) => {
                if (!e) {
                    urlParams.delete("fuel");
                    //urlParams.set("page",1);
                    setFuel(null);
                } else {
                    setFuel(e);
                    urlParams.set("fuel", e.value);
                }

                
            }}
        />

    )
}

export default Fuel
