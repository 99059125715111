import { React } from "react";

function SectionHead({ top, heading, paragraph }) {
  return (
    <div className="row justify-content-md-center pt-5">
      <div className="col-lg-8">
        <div className="wo-sectionhead">
          <div className="wo-sectionhead__title">
            <span>{top}</span>
            <h2>{heading}</h2>
          </div>
          <div className="wo-sectionhead__description">
            <p>{paragraph}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHead;
