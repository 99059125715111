import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import VehicleDetails from "../components/vehicles/VehicleDetails";
import VehicleInfo from "../components/vehicles/VehicleInfo";
import axiosInstance from "../helpers/axios";

function CarPage({ match }) {
  const [vehicle, setVehicle] = useState();

  const uid = match.params.uid;

  useEffect(() => {
    axiosInstance
      .get(`ajax/vehicle/${uid}`)
      .then((res) => {
        console.log(res.data.result);
        setVehicle(res.data.result);
        //setImages(res.data.result.images);
        //setMakes(res.data);
      })
      .then();
  }, [uid]);
  return (
    <>
      <Header />
      <main className="wo-main">
        <div className="wo-main-section">
          <div className="container">
            <div className="row">
              <div id="wo-twocolumns" className="wo-twocolumns">
                <div className="col-lg-8">
                  <VehicleDetails vehicle={vehicle} />
                </div>
                <div className="col-lg-4">
                  <VehicleInfo vehicle={vehicle} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default CarPage;
