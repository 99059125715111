import React, { useState, useEffect } from "react";
import axiosInstance from "../../helpers/axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";

function PartsSearch({ makes, state, settings }) {
  const categories = useSelector((state) => state.categories.value);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [variations, setVariations] = useState([]);
  const [engines, setEngines] = useState([]);

  const [category, setCategory] = useState("");
  const [make, setMake] = useState("");
  const [variation, setVariation] = useState("");
  const [engine, setEngine] = useState("");

  const toast = useToast();

  const validate = (values) => {
    const errors = {};

    if (
      !values.category ||
      values.category === null ||
      values.category === ""
    ) {
      errors.category = "Please select make";
      toast({
        description: "Please select category",
        position: "top-left",
        status: "error",
      });
    }
    // if (!values.make || values.make === "null") {
    //   errors.make = "Please select make";
    // }

    // if (!values.model || values.model === "null") {
    //   errors.model = "Please select model";
    // }

    // if (!values.variation || values.variation === "null") {
    //   errors.variation = "Please select model variation";
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      category: category,
      make: make,
      variation: variation,
      engine: engine,
      state: state ? "new" : "used",
    },
    validate,
    onSubmit: (value) => {
      let categorySlug = value.category;
      delete value.category;
      var esc = encodeURIComponent;
      var query = Object.keys(value)
        .map((k) => esc(k) + "=" + esc(value[k]))
        .join("&");
      window.location.href = "/parts/" + categorySlug + "?" + query;
      //window.location.reload();
    },
  });

  useEffect(() => {
    let cats = [];
    categories.map((category) => {
      return cats.push({
        label: category.name,
        options: category.subcategories.map((subcategory) => {
          return {
            label: subcategory.subcategory_name,
            value: subcategory.subcategory_slug,
          };
        }),
      });
    });
    setCategoryOptions(cats);
  }, [categories]);

  function getModels(e) {
    axiosInstance
      .get(`ajax/variations/${e}`)
      .then((res) => {
        setVariations(res.data);
      })
      .then();
  }

  function getEngines(e) {
    axiosInstance
      .get(`ajax/engines/${e}`)
      .then((res) => {
        setEngines(res.data);
      })
      .then();
  }

  const customStyles = {
    
  
    groupHeading: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const fontSize = "font-size: 14px";
      const background = "#123652";
      const color = "#ffffff";
      const padding = "10px";
      return { ...provided, opacity, transition, fontSize, background, color, padding};
    }
  }

  return (
    <>
      <div className="wo-tabtitle">
        <h4>
          <span>{settings?.partsTop}</span>{settings?.partsBottom}
        </h4>
      </div>
      <form
        className="wo-themeform wo-bannerform mt-3"
        onSubmit={formik.handleSubmit}
      >
        <fieldset>
          <div className="form-group mb-2">
            <label className="wo-titleinput">I’m Looking For:</label>
            <Select
              className="w-100 react-select-container"
              placeholder="Category"
              isSearchable
              required
              styles={customStyles}
              name="category"
              onChange={(e) => {
                console.log(e);
                formik.values.category = e.value;
                setCategory(e.value);
                // if (e.id) {
                //   getModels(e.id);
                // }
              }}
              options={categoryOptions}
            />
          </div>
          <div className="form-group mb-2">
            <label className="wo-titleinput">Vehicle Make:</label>
            <Select
              className="w-100 react-select-container"
              placeholder="Vehicle Make"
              isSearchable
              required
              name="make"
              isClearable
              options={makes}
              onChange={(e) => {
                console.log(e);
                if (e) {
                  getModels(e.value);
                  formik.values.make = e.value;
                  setMake(e.value);
                } else {
                  setEngines([]);
                  setVariations([]);
                  formik.values.engine = "";
                  formik.values.variation = "";
                  formik.values.make = "";
                }

                // if (e.id) {
                //   getModels(e.id);
                // }
              }}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="" className="wo-titleinput">
              Vehicle Variation
            </label>
            <Select
              className="w-100 react-select-container"
              placeholder="Vehicle Variation"
              isSearchable
              name="model"
              isClearable
              onChange={(e) => {
                if (e) {
                  getEngines(e.value);
                  formik.values.variation = e.value;
                  setVariation(e.value);
                } else {
                  setEngines([]);
                  formik.values.engine = "";
                  formik.values.variation = "";
                }

                // if (e.id) {
                //   getModels(e.id);
                // }
              }}
              // onChange={(e) => {
              //   if (!e) {
              //     return;
              //   }
              //   if (e.value) {
              //     getMaxYears(e.value);
              //   }
              // }}
              options={variations}
            />
          </div>

          <div className="form-group mb-2">
            <label htmlFor="" className="wo-titleinput">
              Vehicle Engine
            </label>
            <Select
              className="w-100 react-select-container"
              placeholder="Vehicle Engine"
              isSearchable
              name="engine"
              isClearable
              options={engines}
              onChange={(e) => {
                if (e) {
                  formik.values.engine = e.value;
                  setEngine(e.value);
                } else {
                  formik.values.engine = "";
                  setEngine([]);
                }

                // if (e.id) {
                //   getModels(e.id);
                // }
              }}
            />
          </div>
          {/* <input name="state" value={state ? "new" : "used"} hidden /> */}
          <div className="form-group mb-2 wo-form-btns">
            <button type="submit" className="wo-btn">
              Browse Parts
            </button>
          </div>
        </fieldset>
      </form>
    </>
  );
}

export default PartsSearch;
