import React, { useState } from "react";
import TopBar from "./TopBar";
import Nav from "./Nav";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

function Header({ type = `cars` }) {
  const [consent, setConsent] = useState(localStorage.getItem("consent"));

  return (
    <>
      <header className="wo-header" data-select2-id="32">
        {!consent && (
          <Alert status="info" pos="fixed" bottom="0" zIndex={2}>
            <AlertIcon />

            <AlertDescription>
              <AlertTitle mr={2}>This site uses cookies!</AlertTitle>
              <small>
                We use cookies to ehance your experience, for analytics and to
                show you offers tailored to your interests on our site. By using
                this site your agree to our use of cookies and smililar
                technoligies.
              </small>
            </AlertDescription>
            <CloseButton
              position="absolute"
              bg="#fdc500"
              onClick={() => {
                setConsent(true);
                localStorage.setItem("consent", true);
              }}
              right="8px"
              top="8px"
            />
          </Alert>
        )}
        <TopBar consent={consent} />
        <Nav type={type} />
      </header>
    </>
  );
}

export default Header;
