import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import CarsList from "../components/vehicles/CarsList";
import SearchHead from "../components/vehicles/SearchHead";
import axiosInstance from "../helpers/axios";

function CarsPage() {

  const [page, setPage] = useState(1);
  const toast = useToast();
  const [searchData, setSearchData] = useState();
  const [searchParams] = useState(new URLSearchParams(window.location.search));
  useEffect(() => {
    axiosInstance
      .get('ajax/searchData')
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setSearchData(data);
      })
      .catch((err) => {
        toast({
          description: "Error occured while fetching data. Reload page",
          position: "top-right",
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      });
  }, [toast]);

  return (
    <>
      <Header />
      <main className="wo-main  mt-2">
        <div className="nav-icon4" id="nav-icon4" onClick={window['togglenav']}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className="wo-main-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <SearchHead setPage={setPage} searchData={searchData} />
              </div>
              <div className="col-12">
                <CarsList page={page} setPage={setPage} searchParams={searchParams} />
              </div>
            </div>
          </div>
        </div>
      </main>
      
    </>
  );
}

export default CarsPage;
