import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../helpers/axios";
import { setCategories } from "../../helpers/redux/categorySlice";
import { addVehicle } from "../../helpers/redux/vehicleSlice";
import { useLocation, Link, Redirect } from "react-router-dom";
import Select from "react-select";

function PartsSidebar() {
  const categories = useSelector((state) => state.categories.value);
  const myvehicles = useSelector((state) => state.myvehicles.value);

  const [options, setOptions] = useState([]);
  const option = useSelector((state) => state.myvehicle.value);
  const [myoption, setMyOption] = useState();
  console.log(option);

  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    let myoptions = [];
    myvehicles.map((myvehicle) => {
      myoptions.push({
        value:
          myvehicle.make.id +
          "-MOTION-" +
          myvehicle.variation.value +
          "-MOTION-" +
          myvehicle.engine.value,
        label:
          myvehicle.make.name +
          " " +
          myvehicle.variation.label +
          " " +
          myvehicle.engine.label,
      });
      if (
        myvehicle.make.id +
          "-MOTION-" +
          myvehicle.variation.value +
          "-MOTION-" +
          myvehicle.engine.value ===
        option
      ) {
        setMyOption({
          value:
            myvehicle.make.id +
            "-MOTION-" +
            myvehicle.variation.value +
            "-MOTION-" +
            myvehicle.engine.value,
          label:
            myvehicle.make.name +
            " " +
            myvehicle.variation.label +
            " " +
            myvehicle.engine.label,
        });
      }
      return false;
    });

    setOptions(myoptions);

    if (categories.length < 1) {
      axiosInstance
        .get("ajax/parts-categories?subcategories")
        .then((res) => {
          dispatch(setCategories(res.data));
        })
        .then();
    }
  }, [categories.length, dispatch, myvehicles, option]);

  return (
    <aside
      id="wo-sidebar"
      className="wo-sidebar wo-dnavbar"
      style={{ background: "#123652", padding: "20px", borderRadius: "5px" }}
    >
      <h5 style={{ color: "#fff" }}>Car Parts</h5>
      {options.length > 0 && (
        <>
          <hr />
          <h6 style={{ color: "#fff" }}>My Vehicle</h6>
          <Select
            options={options}
            value={myoption}
            isClearable
            onChange={(e) => {
              console.log(e);
              if (!e) {
                setMyOption(undefined);
                dispatch(addVehicle(undefined));
              } else {
                setMyOption(e);
                dispatch(addVehicle(e.value));
              }
            }}
          />
          <hr />
        </>
      )}

      {categories.map(
        (category, index) =>
          category.subcategories.length > 0 && (
            <div className="wo-widget wo-widgetfilter" key={index}>
              <div
                className=""
                id={`title` + index}
                role="button"
                data-toggle="collapse"
                data-target={`#collapse` + index}
                aria-expanded="false"
                style={{ padding: "20px" }}
              >
                <a
                  href={`${search}#`}
                  style={{ color: "#fff", fontWeight: "400" }}
                >
                  <span>
                    <img
                      alt="icon"
                      src={category.icon}
                      style={{ height: "20px", marginRight: "10px" }}
                    ></img>
                    {category.name}
                  </span>
                </a>
              </div>
              <div
                className="collapse"
                id={`collapse` + index}
                aria-labelledby="title1"
              >
                <div className="wo-widget__content">
                  <ul>
                    {category.subcategories.map((subcategory) => (
                      <li
                        style={{ listStyle: "none" }}
                        key={subcategory.subcategory_id}
                      >
                        <a
                          href={`/parts/${subcategory.subcategory_slug}`}
                          style={{ color: "#3e3e3e" }}
                        >
                          {subcategory.subcategory_name}
                        </a>
                      </li>
                    ))}
                    <li style={{ listStyle: "none" }}>
                      <a
                        href={`/parts?category=${category.slug}`}
                        style={{ color: "#3e3e3e" }}
                      >
                        View All
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
      )}
    </aside>
  );
}

export default PartsSidebar;
