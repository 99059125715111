import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import { removeCompare } from "../helpers/redux/compareSlice";

function CompareCars() {
  const vehicles = useSelector((state) => state.comparelist.value);
  const dispatch = useDispatch();
  function numwithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      <Header />
      <main className="wo-main overflow-hidden">
        <section className="wo-main-section">
          <div className="container">
            <div className="row">
              <div className="wo-vehiclescomwrap">
                <div className="col-lg-3">
                  <div className="wo-vcomtitle">
                    <h2>
                      Choose Vehicles To See Realtime Comparison
                      <span>
                        <i className="fas fa-angle-right"></i>
                      </span>
                    </h2>
                  </div>
                </div>
                {vehicles.map((vehicle) => (
                  <div className="col-md-6 col-lg-3">
                    <div className="wo-vehiclescom">
                      <figure className="wo-vehiclescom__img">
                        {vehicle.images.length > 0 && (
                          <img
                            src={vehicle.image_base + vehicle.images[0]}
                            alt="img description"
                          />
                        )}

                        <figcaption>
                          <Link
                            to="#"
                            className="wo-removebtn"
                            onClick={() => {
                              dispatch(removeCompare(vehicle));
                              //removeCompare(vehicle);
                            }}
                          >
                            Remove
                          </Link>
                        </figcaption>
                      </figure>
                      <div className="wo-vehicles w-100 pb-0">
                        <div className="wo-vehicles__tags">
                          <Link to="#">{vehicle.body}</Link>
                        </div>
                        <div className="wo-vehicles__title">
                          <h5>
                            <Link to="#">
                              {vehicle.year +
                                " " +
                                vehicle.make +
                                " " +
                                vehicle.model}
                            </Link>
                            <span>
                              <sup>$</sup>
                              {numwithCommas(vehicle.price)}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="wo-featureslist">
                        <ul>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-03.png"
                                  }
                                  alt="img description"
                                />
                                Body Type :
                              </strong>
                              <span>{vehicle.body}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-01.png"
                                  }
                                  alt="img description"
                                />
                                Engine Capacity :
                              </strong>
                              <span>{vehicle.cc}cc</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-13.png"
                                  }
                                  alt="img description"
                                />
                                Transmission :
                              </strong>
                              <span>{vehicle.transmission}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-08.png"
                                  }
                                  alt="img description"
                                />
                                Fuel Type :
                              </strong>
                              <span>{vehicle.fuel}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-05.png"
                                  }
                                  alt="img description"
                                />
                                Drive :
                              </strong>
                              <span>{vehicle.drivetrain}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={process.env.PUBLIC_URL + "/images/theme-icon/img-04.png"
                                  }
                                  alt="img description"
                                />
                                Rim Size :
                              </strong>
                              <span>{vehicle.rim}</span>
                            </div>
                        </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-02.png"
                                  }
                                  alt="img description"
                                />
                                Assembly :
                              </strong>
                              <span>{vehicle.assembly}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-07.png"
                                  }
                                  alt="img description"
                                />
                                Central Lock :
                              </strong>
                              <span>{vehicle.central_lock && `Yes`}</span>
                            </div>
                          </li>

                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-04.png"
                                  }
                                  alt="img description"
                                />
                                Color
                              </strong>
                              <span>{vehicle.color}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-09.png"
                                  }
                                  alt="img description"
                                />
                                Steering Type :
                              </strong>
                              <span>{vehicle.steering}</span>
                            </div>
                          </li>

                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-10.png"
                                  }
                                  alt="img description"
                                />
                                Windows :
                              </strong>
                              <span>
                                {vehicle.windows ? `Power ` : `Manual `}
                                Windows
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-12.png"
                                  }
                                  alt="img description"
                                />
                                Sunroof :
                              </strong>
                              <span>{vehicle.sunroof && `Yes`}</span>
                            </div>
                          </li>
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-11.png"
                                  }
                                  alt="img description"
                                />
                                Seats :
                              </strong>
                              <span>{vehicle.seats}</span>
                            </div>
                          </li>
                          {vehicle.load.length > 0 && (
                            <li>
                              <div className="wo-featureslistcontent">
                                <strong>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/images/vehicle-single/features/icon/img-07.png"
                                    }
                                    alt="img description"
                                  />
                                  Load Cap :
                                </strong>
                                <span>{vehicle.load}</span>
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="wo-featureslistcontent">
                              <strong>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/vehicle-single/features/icon/img-11.png"
                                  }
                                  alt="img description"
                                />
                                Seats :
                              </strong>
                              <span>{vehicle.seats}</span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <Link to={"/vehicle/"+vehicle.id} className="wo-btn">
                        Buy This vehicle
                      </Link>
                    </div>
                  </div>
                ))}

                <div className="col-md-6 col-lg-3">
                  <div className="wo-vehiclesupload">
                    <Link to="/vehicles" className="wo-vehiclesupload__btn">
                      <i className="fas fa-plus"></i>
                      <span>Add Vehicle</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CompareCars;
