import React from "react";
import CarsTopSwitcher from "./CarsTopSwitcher";
import PartsTopSwitcher from "./PartsTopSwitcher";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/google/AuthContext";
import { useSelector } from "react-redux";

function Nav({ type }) {
  const { currentUser, logout } = useAuth();
  const cart = useSelector((state) => state.cart.value);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="wo-header__content">
            <div className="wo-logo">
              <Link to="/">
                <img src="/images/logo.png" alt="img-description" />
              </Link>
            </div>
            <div className="wo-header-nav-center">
              <nav className="navbar-expand-xl">
                <button
                  className="navbar-toggler wo-btn-small"
                  type="button"
                  data-toggle="collapse"
                  data-target="#jbMainNavbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-bars"></i> Menu
                </button>
                <div id="jbMainNavbar" className="collapse navbar-collapse">
                  <ul className="navbar-nav wo-navbarnav">
                    <li>
                      <Link to="/parts">Spare Parts</Link>
                    </li>
                    <li>
                      <Link to="/vehicles">Used Cars</Link>
                    </li>
                    {/* <li>
                      <Link to="/parts?state=used">Car Breaking</Link>
                    </li> */}
                    <li>
                      <em>new</em>
                      <Link to="/order">Track Order</Link>
                    </li>
                    {/* <li>
                      <Link to="/about">About Us</Link>
                    </li> */}
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </nav>
              {type === `cars` ? <CarsTopSwitcher /> : <PartsTopSwitcher />}
            </div>
            <div className="wo-header-user">
              {currentUser ? (
                <>
                  <div className="wo-user">
                    <div className="wo-user__title">
                      <Link to="#">
                        {currentUser.displayName}
                        <em>General User</em>
                      </Link>
                    </div>
                    <figure className="wo-user__img">
                      <img
                        src={
                          currentUser.photoURL
                            ? currentUser.photoURL
                            : process.env.PUBLIC_URL +
                              "/images/index/user-img2.png"
                        }
                        style={{ height: "40px", width: "40px" }}
                        alt="img description"
                      />
                    </figure>
                  </div>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/checkout">
                        <i className="fas fa-shopping-cart" /> Checkout (
                        {cart.length})
                      </Link>
                    </li>
                    <li>
                      <Link to="/sell-vehicle">
                        <i className="fas fa-car-alt" />
                        Sell Vehicle
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-vehicles">
                        <i className="fas fa-car-alt" />
                        My Vehicles
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={logout}>
                        <i className="fas fa-power-off" /> Logout
                      </Link>
                    </li>
                  </ul>
                </>
              ) : (
                <Link
                  className="wo-btn"
                  to="/login"
                  style={{
                    padding: "0 !important",
                    lineHeight: "33px",
                    minWidth: "69px",
                  }}
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
