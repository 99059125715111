import React from "react";
import { Center, Spinner } from "@chakra-ui/react";
import Slider from "react-slick";
import VehicleAdvert from "./VehicleAdvert";
import DisplayAdvert from "./DisplayAdvert";

function HeroSlider({ loading, ads }) {
  if (loading) {
    return (
      <Center className="wo-banner__silder">
        <Spinner size="xl" color="orange.200" />
      </Center>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider className="wo-banner__silder col-sm-pull-4" {...settings}>
      {ads.map((ad, index) =>
        ad.type === "vehicle" ? (
          <VehicleAdvert key={index} vehicle={ad} />
        ) : (
          <DisplayAdvert key={index} advert={ad} />
        )
      )}
    </Slider>
  );
}

export default HeroSlider;
