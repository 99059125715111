import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../helpers/redux/cartSlice";
import { FacebookShareButton } from "react-share";

function PartDetails({ part }) {
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(part.variations[0].variation_price);
  const [variation, setVariation] = useState(part.variations[0]);
  const toast = useToast();
  const dispatch = useDispatch();


  
  function increase() {
    setCount(count + 1);
  }
  function reduce() {
    if (count > 1) {
      setCount(count - 1);
    } else {
      toast({
        description: "Min order is 1",
        position: "top-right",
        status: "error",
      });
    }
  }
  return (
    <>
      <div className="wo-partshead ">
        <div className="wo-partshead__title">
          <h2>{part.name}</h2>
          <span>
            <sup>$</sup>
            {(price * count).toFixed(2)}

            {part.shipping && <em>(Free Delivery)</em>}
          </span>
        </div>
        <ul className="wo-vsinglehead__share">
          <li>
            <FacebookShareButton
              quote={part.name + " $" + price}
              hashtag="#motion7"
              url={"https://google.com"}
            >
              <span to="#">
                <i className="fas fa-share-alt" />
                Share Product
              </span>
            </FacebookShareButton>
          </li>
        </ul>
      </div>
      <div className="wo-partcolors">
        <h3 className="mb-3">Available Variations:</h3>

        <div
          className="wo-radioholder"
          style={{
            position: "relative",
          }}
        >
          {part.variations.map((v, index) => (
            <span className="wo-radio" key={index}>
              <input
                type="radio"
                id={v.variation_id}
                name="variation"
                value={v.variation_id}
                readOnly
                defaultChecked={index === 0 && true}
              />
              <label
                htmlFor={v.variation_id}
                onClick={() => {
                  setVariation(v);
                  setPrice(v.variation_price);
                }}
              >
                <span>
                  <i className="far fa-circle" />
                  {v.variation_name}
                  {"  $"}
                  {v.variation_price}
                </span>
              </label>
            </span>
          ))}
        </div>
      </div>
      <div className="wo-partstock">
        <h3>
          Quantity: <span className="sl-green">In Stock</span>
        </h3>
        <div className="wo-partstock__content">
          <form className="wo-vlaue-btn">
            <Link to="#" onClick={reduce} className="wo-input-decrement">
              -
            </Link>
            <input
              className="wo-input-number"
              type="number"
              value={count}
              onChange={(e) => {
                console.log(e);
              }}
              min={0}
              max={1000}
            />
            <small
              style={{
                margin: "auto",
                fontWeight: "100",
                fontSize: "14px",
              }}
            >
              {/* {count} */}
            </small>
            <Link to="#" onClick={increase} className="wo-input-increment">
              +
            </Link>
          </form>
          <Link
            to="#"
            className="wo-btn wo-cart-btn"
            onClick={() => {
              let newpart = { ...part };
              let newvariation = { ...variation };
              newpart.variation = newvariation;
              newpart.count = count;
              // addToCart(part, count);
              dispatch(addToCart(newpart));
            }}
          >
            Add To Cart
          </Link>
        </div>
      </div>
    </>
  );
}

export default PartDetails;
