import React, { useState, useEffect } from 'react'
import Header from '../components/header/Header'
import { CircularProgress, CircularProgressLabel, Checkbox } from "@chakra-ui/react"
import axiosInstance from '../helpers/axios';
import Select from "react-select";
import { useToast } from "@chakra-ui/react";
import Slim from '../helpers/slim/slim.react';
import "../helpers/slim/slim.min.css";

const SellVehicle = () => {

    const [models, setModels] = useState([]);
    const [makes, setMakes] = useState([]);
    const [fuelOptions, setFuelOptions] = useState([]);
   
    const [driveOptions, setDriveOptions] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
   
    const [transmissionOptions, setTransmissionOptions] = useState([]);
   
    const [steeringOptions, setSteeringOptions] = useState([]);
    
    const [assemblyOptions, setAssemblyOptions] = useState([]);
   
    const [loading, setLoading] = useState(true);
    const [modelloading, setModelLoading] = useState(false);
    

    const [progress, setProgress]  = useState(0);

    const [make, setMake] = useState();
    const [model, setModel] = useState();
    const [drive, setDrive] = useState();
    const [fuel, setFuel] = useState();
    const [transmission, setTransmission] = useState();
    const [color, setColor] = useState();
    const [steering, setSteering] = useState("");
    const [bodyType, setBodyType] = useState("");
    const [assembly, setAssembly] = useState("");
    const [engine, setEngine] = useState("");
    const [modelCode, setModelCode] = useState("");
    const [distance,setDistance] = useState("");
    const [wheelSize, setWheelSize] = useState("");
    const [clearance, setClearance] = useState("");
    const [capacity, setCapacity] = useState("");
    const [seats, setSeats] = useState("");
    const [year, setYear] = useState("");
    const [price, setPrice] = useState("");
    const [windows, setWindows] = useState(false);
    const [abs, setABS] = useState(false);
    const [aircon, setAirCon] = useState(false);
    const [sunroof, setSunroof] = useState(false);
    const [centralLock, setCentralLock] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");

    const toast = useToast();

    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get("ajax")
            .then((res) => {
                //console.log(res.data);
                if (res.data.error !== undefined && res.data.error === false) {
                    setMakes(res.data.makes)
                    
                }
                return true
            })
            .then(()=>{
                axiosInstance
                .get('ajax/searchData')
                .then((res) => {
                  return res.data;
                })
                .then((data) => {
                    setLoading(false);
                  //   setSearchData(data);
                     //fuel 
                    let fuelarray = Object.entries(data.fuel);
                    let fuelOptions = [];
                    fuelarray.map((arr) => {
                        fuelOptions.push({ value: arr[0], label: arr[1] });
                        return arr;
                    });
                    setFuelOptions(fuelOptions);
                    // drive
                    let drivearray = Object.entries(data.drive);
                    let driveOptions = [];
                    drivearray.map((arr) => {
                        driveOptions.push({ value: arr[0], label: arr[1] }); return arr;
                    });
                    setDriveOptions(driveOptions);

                    // color
                    let colorarray = Object.entries(data.color);
                    let colorOptions = [];
                    colorarray.map((arr) => {
                        colorOptions.push({ value: arr[0], label: arr[1] }); return arr;
                    });
                    setColorOptions(colorOptions);

                    // transmission
                    let transmissionarray = Object.entries(data.transmission);
                    let transOptions = [];
                    transmissionarray.map((arr) => {
                        transOptions.push({ value: arr[0], label: arr[1] }); return arr;
                    });
                    setTransmissionOptions(transOptions);

                     // steering
                     let steeringarray = Object.entries(data.steering);
                     let steeringOptions = [];
                     steeringarray.map((arr) => {
                        steeringOptions.push({ value: arr[0], label: arr[1] }); return arr;
                     });
                     setSteeringOptions(steeringOptions);

                     // assembly
                     let assemblyarray = data.countries;
                     let assemblyoptions = [];
                     assemblyarray.map((arr) => {

                        assemblyoptions.push({ value:arr.Code, label: arr.Name }); return arr;
                     });
                     setAssemblyOptions(assemblyoptions); 

                      // body
                      let bodyarray =  Object.entries(data.types);
                    //   console.log(bodyarray);
                      let bodytypes = [];
                      bodyarray.map((arr) => {
                        bodytypes.push({ value: arr[0], label: arr[1] }); return arr;
                      });
                      setBodyTypes(bodytypes); 
                })
                .catch((err) => {
                  toast({
                    description: "Error occured while fetching data. Reload page",
                    position: "top-right",
                    status: "error",
                    variant: "top-accent",
                    isClosable: true,
                  });
                });
            })
            .catch(() => {
                setLoading(false);
            });
    }, [toast]);



    useEffect(() => {
        
        let p = 0;

        if(make){
            p += 5;
        }
        if(model){
            p += 5;
        }
        if(year){
            p += 5;
        }
        if(price){
            p += 5;
        }
        if(name){
            p += 5;
        }
        if(image1){
            p += 5;
        }

        if(modelCode){
            p += 5;
        }
        
        if(email){
            p += 5;
        }
        if(phone){
            p += 5;
        }
        if(address){
            p += 5;
        }

        if(drive){
            p += 5;
        }

        if(fuel){
            p += 5;
        }

        if(transmission){
            p += 5;
        }

        if(color){
            p += 10;
        }

        if(steering){
            p += 5;
        }

        if(assembly){
            p += 5;
        }

        if(engine){
            p += 5;
        }

    
        if(distance){
            p += 5;
        }

        if(bodyType){
            p += 5;
        }


   

        setProgress(p);

    }, [make,model,year,price,name,email,phone,address, image1, image2, assembly, color, distance, drive, engine, fuel, modelCode, steering, transmission, bodyType]);


    function submit(){
        setLoading(true);

        let data = {
            make: make?.id,
            model:model?.slug,
            year:year,
            price:price,
            name:name,
            email:email,
            phone:phone,
            address:address,
            drive:drive,
            fuel:fuel,
            transmission:transmission,
            color:color,
            steering:steering,
            assembly:assembly,
            engine:engine,
            modelcode:modelCode,
            distance:distance,
            rim:wheelSize,
            clearance:clearance,
            load:capacity,
            seats:seats,
            windows:windows,
            abs:abs,
            aircon:aircon,
            sunroof:sunroof,
            central:centralLock,
            images:[image1,image2],
            body:bodyType
        };
        axiosInstance
        .post('/ajax/sell',JSON.stringify(data))
        .then(function (response) {
            setMake(false);
            setModel(false);
            setYear("");
            setPrice("");
            setName("");
            setEmail("");
            setPhone("");
            setAddress("");
            setDrive("");
            setFuel("");
            setTransmission("");
            setColor("");
            setSteering("");
            setAssembly("");
            setEngine("");
            setModelCode("");
            setDistance("");
            setWheelSize("");
            setClearance("");
            setCapacity("");
            setSeats("");
            setBodyType("");
            setWindows(false);
            setABS(false);
            setAirCon(false);
            setSunroof(false);
            setCentralLock(false);
            toast({
                description: "Vehicle Information Sent",
                position: "top-right",
                status: "success",
              });
            setLoading(false);  
        }).catch(function (error){
            setMake(false);
            setModel(false);
            setYear("");
            setPrice("");
            setName("");
            setEmail("");
            setPhone("");
            setAddress("");
            setDrive("");
            setFuel("");
            setTransmission("");
            setColor("");
            setSteering("");
            setAssembly("");
            setEngine("");
            setModelCode("");
            setDistance("");
            setWheelSize("");
            setClearance("");
            setCapacity("");
            setSeats("");
            setBodyType("");
            setWindows(false);
            setABS(false);
            setAirCon(false);
            setSunroof(false);
            setCentralLock(false);
            toast({
                description: "Error sending email",
                position: "top-right",
                status: "error",
              });
            setLoading(false); 
        });
    }

    function getModels(e) {
        setModelLoading(true);
        setModel({});
        axiosInstance
            .get(`ajax/models/${e}`)
            .then((res) => {
                setModels(res.data);
                setModelLoading(false);
            }).catch((err)=>{
                setModelLoading(false);
            })
    }


    const willTransform = (data,ready) =>{
        var watermark = new Image();
        watermark.setAttribute('crossorigin','anonymous');
        watermark.onload = function () {
            // set watermark x and y offset to 5% of output image width
            var offset = data.output.width * 0.05;

            // set watermark width to 25% of the output image width
            var width = data.output.width * 0.25;
            var height = width * (this.naturalHeight / this.naturalWidth);

            // get the drawing context for the output image
            var ctx = data.output.image.getContext("2d");

            // make watermark transparant
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalAlpha
            ctx.globalAlpha = 0.75;

            // have watermark blend with background image
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
            ctx.globalCompositeOperation = "multiply";

            // draw the image
            ctx.drawImage(watermark, offset, offset, width, height);

            // continue saving the data
            //console.log(ready);
            ready(data);
        };

        watermark.src = "/images/m7-2.png";
    }


    return (
        <>
            <Header />

            <main className="wo-main">

                <section className="wo-main-section">
                    <div className="container">
                    <div className="wo-dhb-main_content wo-packages-features mb-3">
                                    <div className="wo-innerbannertwo-wrap mt-0 w-100">
                                        <div className="wo-innerbannertwo-wrap__pakage">
                                            <div className="wo-articles-title">
                                                <h3>Start Selling &amp; Get A Quick Sale</h3>
                                            </div>
                                            <div className="wo-description">
                                                <small className="text-muted">We are excited for your interest in listing your vehicle with us. Fill up the form below and one of our agents will give you a call</small>
                                            </div>
                                        </div>
                                       
                                        <div id="particles-js" className="wo-particles particles-js"><canvas className="particles-js-canvas-el" width={825} height={110} style={{ width: '100%', height: '100%' }} /></div>
                                    </div>
                                </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-9">


                                

                             

                                <fieldset className="wo-dbpost p-3 row">
                                    <div className="col-12">
                                    <h6 className="mb-3">Vehicle Details</h6>
                                    <hr />
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label className="wo-titleinput">Image (1)</label>
                                        <div className="slim">

                                            <Slim 
                                            ratio="730:400"  
                                            label="Select your vehicle image"
                                            didSave={(e)=>{
                                               setImage1(e.output);
                                            }}
                                            didRemove={(e)=>{
                                                setImage1();
                                            }}
                                            willTransform={willTransform}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label className="wo-titleinput">Image (2)</label>
                                        <div className="slim">

                                            <Slim
                                             ratio="730:400"  
                                             label="Select your vehicle image"
                                             didSave={(e)=>{
                                                setImage2(e.output);
                                             }}
                                             didRemove={(e)=>{
                                                setImage2();
                                            }}
                                            willTransform={willTransform}
                                            />

                                        </div>
                                    </div>
                                   
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Select Make:</label>
                                        <Select
                                            className="w-100 react-select-container"
                                            placeholder="Vehicle Make"
                                            isSearchable
                                            required
                                            name="make"
                                            onChange={(e) => {
                                                if(!e){
                                                    setMake(false);
                                                }
                                                setMake(e);
                                                if (e.slug) {
                                                    getModels(e.slug);
                                                }
                                            }}
                                            options={makes}
                                        />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Vehicle Model:</label>
                                        <Select
                                            className="w-100 react-select-container"
                                            placeholder="Vehicle Model"
                                            isSearchable
                                            required
                                            name="model"
                                            isLoading={modelloading}
                                            value={model}
                                            options={models}
                                            onChange={(e) => { 
                                                setModel(e);
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Model Code</label>
                                        <input type="text" value={modelCode} onChange={(e)=>{
                                            setModelCode(e.target.value);
                                        }} className="form-control" placeholder="Model Code e.g 325i, c180i" />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Body</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Body Type"
                                            isSearchable
                                            isLoading={bodyTypes.length<1?true:false}
                                            name="body"
                                            value={bodyType}
                                            isClearable
                                            options={bodyTypes}
                                            onChange={(e) => {
                                                if (!e) {
                                                    setBodyType(null);
                                                } else {
                                                    setBodyType(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Fuel</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Fuel Type"
                                            isSearchable
                                            isLoading={fuelOptions.length<1?true:false}
                                            name="fuel"
                                            value={fuel}
                                            isClearable
                                            options={fuelOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                    setFuel(null);
                                                } else {
                                                    setFuel(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Transmission</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Transmission"
                                            isSearchable
                                            isLoading={transmissionOptions.length<1?true:false}
                                            name="transmission"
                                            value={transmission}
                                            isClearable
                                            options={transmissionOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                    setTransmission(null);
                                                } else {
                                                    setTransmission(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Color</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Color"
                                            isSearchable
                                            isLoading={colorOptions.length<1?true:false}
                                            name="color"
                                            value={color}
                                            isClearable
                                            options={colorOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                    setColor(null);
                                                } else {
                                                    setColor(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Drive Train</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Drive Train"
                                            isSearchable
                                            isLoading={driveOptions.length<1?true:false}
                                            name="drive"
                                            value={drive}
                                            isClearable
                                            options={driveOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                   
                                                    setDrive(null);
                                                } else {
                                                    setDrive(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Steering</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Steering"
                                            isSearchable
                                            isLoading={steeringOptions.length<1?true:false}
                                            name="steering"
                                            value={steering}
                                            isClearable
                                            options={steeringOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                   
                                                    setSteering(null);
                                                } else {
                                                    setSteering(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>


                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Assembly</label>
                                        <Select
                                            className="w-100 react-select-container mr-1"
                                            placeholder="Assemble Country"
                                            isSearchable
                                            isLoading={assemblyOptions.length<1?true:false}
                                            name="assembly"
                                            value={assembly}
                                            isClearable
                                            options={assemblyOptions}
                                            onChange={(e) => {
                                                if (!e) {
                                                   
                                                    setAssembly(null);
                                                } else {
                                                    setAssembly(e);
                                                }

                                                
                                            }}
                                        />
                                    </div>


                                   
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Year</label>
                                        <input type="number" value={year} onChange={(e)=>{
                                            setYear(e.target.value);
                                        }} className="form-control" placeholder="Year" />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Price</label>
                                        <input type="number" value={price} onChange={(e)=>{
                                            setPrice(e.target.value);
                                        }} className="form-control" placeholder="Price" />
                                    </div>


                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Engine Size</label>
                                        <input type="number" value={engine} onChange={(e)=>{
                                            setEngine(e.target.value);
                                        }} className="form-control" placeholder="Engine Size e.g 1490" />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Distance</label>
                                        <input type="number" value={distance} onChange={(e)=>{
                                            setDistance(e.target.value);
                                        }} className="form-control" placeholder="Distance e.g 80,000" />
                                    </div>


                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Wheel Size</label>
                                        <input type="number" value={wheelSize} onChange={(e)=>{
                                            setWheelSize(e.target.value);
                                        }} className="form-control" placeholder="Wheel Size in Inches" />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Seats</label>
                                        <input type="number" value={seats} onChange={(e)=>{
                                            setSeats(e.target.value);
                                        }} className="form-control" placeholder="Seats e.g 5" />
                                    </div>

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Load Capacity</label>
                                        <input type="number" value={capacity} onChange={(e)=>{
                                            setCapacity(e.target.value);
                                        }} className="form-control" placeholder="Load capacity in kilos" />
                                    </div>


                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Ground Clearance</label>
                                        <input type="number" value={clearance} onChange={(e)=>{
                                            setClearance(e.target.value);
                                        }} className="form-control" placeholder="Ground clearance in Inches" />
                                    </div>

                                    <div className="form-group mb-4 col-12">
                                        <Checkbox colorScheme="blue" isChecked={windows} onChange={(e)=>{
                                            setWindows(e.target.checked);
                                        }} className="w-100">
                                            Power Windows
                                        </Checkbox>
                                        <Checkbox colorScheme="blue" isChecked={abs} onChange={(e)=>{
                                            setABS(e.target.checked);
                                        }}  className="w-100">
                                            ABS
                                        </Checkbox>
                                        <Checkbox colorScheme="blue" isChecked={aircon} onChange={(e)=>{
                                            setAirCon(e.target.checked);
                                        }} className="w-100">
                                            Air Conditioned
                                        </Checkbox>
                                        <Checkbox colorScheme="blue" isChecked={sunroof} onChange={(e)=>{
                                            setSunroof(e.target.checked);
                                        }} className="w-100">
                                            Sunroof
                                        </Checkbox>
                                        <Checkbox colorScheme="blue" isChecked={centralLock} onChange={(e)=>{
                                            setCentralLock(e.target.checked);
                                        }} className="w-100">
                                            Central Locking System
                                        </Checkbox>
                                    </div>
                                    

                                    <div className="col-12">
                                    <h6 className="mb-3">Seller Details</h6>
                                    <hr />
                                    </div>
                                   

                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Full Name</label>
                                        <input type="text" value={name} onChange={(e)=>{
                                            setName(e.target.value);
                                        }} className="form-control" placeholder="Full Name" />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Email Address</label>
                                        <input type="email" value={email} onChange={(e)=>{
                                            setEmail(e.target.value);
                                        }} className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Phone</label>
                                        <input value={phone} onChange={(e)=>{
                                            setPhone(e.target.value);
                                        }} type="text" className="form-control" placeholder="Phone" />
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label className="wo-titleinput">Address</label>
                                        <textarea  value={address} col="2" onChange={(e)=>{
                                            setAddress(e.target.value);
                                        }}type="text" className="form-control" placeholder="Address" >{address}</textarea>
                                    </div>
                                    {!loading && <div className="form-group mb-2 wo-form-btns">
                                        {progress === 100  && ( <button onClick={()=>{
                                            submit();
                                        }} className="wo-btn"> Submit </button>)}
                                       
                                    </div> }
                                    

                                    {loading && (
                                        <div className="lds-ripple">
                                            <div></div>
                                            <div></div>
                                        </div>
                                    )}
                                </fieldset>

                              

                            </div>
                            <div className="col-lg-3">
                                <div className="wo-profilestrength">
                                    <div className="wo-strengthhead">
                                        <CircularProgress value={progress} color="green.400" size="100px" thickness="4px" >
                                            <CircularProgressLabel>{progress}%</CircularProgressLabel>
                                        </CircularProgress>
                                        
                               
                                    </div>
                                    <div className="wo-strengthlist">
                                        <ul className="nav navbar-nav">
                                            <li className={make?"nav-item wo-listcom":"nav-item"}>
                                                <a className="active" id="type-tab" data-toggle="tab" href="#type">Select Make</a>
                                            </li>
                                            <li className={model?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Select Model</a>
                                            </li>
                                            <li className={modelCode?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Model Code</a>
                                            </li>
                                            <li className={bodyType?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Body Type</a>
                                            </li>
                                            <li className={drive?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Drive Train</a>
                                            </li>
                                            <li className={fuel?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Fuel</a>
                                            </li>
                                            <li className={transmission?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Transmission</a>
                                            </li>
                                            <li className={color?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Color</a>
                                            </li>
                                            <li className={steering?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Steering</a>
                                            </li>
                                            <li className={assembly?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Assembly</a>
                                            </li>
                                            <li className={engine?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Engine</a>
                                            </li>
                                            <li className={distance?"nav-item wo-listcom":"nav-item"}>
                                                <a id="location-tab" data-toggle="tab" href="#location">Odometer</a>
                                            </li>
                                            <li className={year?"nav-item wo-listcom":"nav-item"}>
                                                <a id="title-tab" data-toggle="tab" href="#title">Vehicle Age</a>
                                            </li>
                                            <li className={price?"nav-item wo-listcom":"nav-item"}>
                                                <a id="description-tab" data-toggle="tab" href="#description">Vehicle Price</a>
                                            </li>
                                            <li className={name?"nav-item wo-listcom":"nav-item"}>
                                                <a id="vdescription-tab" data-toggle="tab" href="#vdescription">Seller Name</a>
                                            </li>
                                            <li className={email?"nav-item wo-listcom":"nav-item"}>
                                                <a id="features-tab" data-toggle="tab" href="#features">Seller Email</a>
                                            </li>
                                            <li className={phone?"nav-item wo-listcom":"nav-item"}>
                                                <a id="addfeatures-tab" data-toggle="tab" href="#addfeatures">Seller Phone</a>
                                            </li>
                                            <li className={address?"nav-item wo-listcom":"nav-item"}>
                                                <a id="history-tab" data-toggle="tab" href="#history">Seller Location</a>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default SellVehicle
