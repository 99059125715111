import React from "react";

function DisplayAdvert({ advert }) {
  return (
    <figure className="h-100">
      <img
        src={advert.image_base + advert.advert_image}
        className="w-100 h-100"
        alt="vehicle"
      />

      <figcaption className="wo-slidercontent">
        <div className="wo-slidercontent__title">
          <h1>
            {/* <Link to={`vehicle/` + vehicle.vehicle_uid}>
              {vehicle.make.make_name} {vehicle.model.model_name}
            </Link> */}
          </h1>
        </div>
        <a
          className="wo-btn"
          href={advert.advert_url}
        >
          {advert.advert_text}
        </a>
      </figcaption>
    </figure>
  );
}

export default DisplayAdvert;
