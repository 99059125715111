import React, {useState, useEffect} from 'react'
import axiosInstance from '../../helpers/axios';
import { useToast } from "@chakra-ui/react";

const ContactForm = () => {

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [details,setDetails] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [myanswer, setMyAnswer] = useState();
    const [a,setA] = useState();
    const [b,setB] = useState();
    const [answer, setAnswer] = useState();
    const toast = useToast();

    useEffect(() => {
       
        reload();


    }, []);


    const reload = () => {

        let first =  Math.floor(Math.random() * 20);
        let second = Math.floor(Math.random() * 20);

        let a_answer = first + second;

        setA(first);
        setB(second);
        setAnswer(a_answer);
        setMyAnswer();

    }

    const sendMessage = ()=> {
        setLoading(true);
        let data = {
            name: name,
            email: email,
            description: details
          };
        axiosInstance
        .post('/ajax/contact-email', JSON.stringify(data))
        .then(function (response) {
            reload();
            setName("");
            setEmail("");
            setDetails("");
            setMyAnswer("");
            setLoading(false);
            toast({
                description: "Email Sent",
                position: "top-right",
                status: "success",
              });
        }).catch(function (error){
            toast({
                description: "Error sending email",
                position: "top-right",
                status: "error",
              });
              setLoading(false); 
        });
        
    }

    return (
        <div className="wo-contactformwrap">
            <div className="wo-replywrap">
                <div className="wo-replytitle">
                    <h3>Let’s Start Talking on New Project</h3>
                    {error.length > 0 && (
                            <small className="text-danger">{error}</small>
                        )}
                </div>
                <form className="wo-themeform wo-replyform">
                    <fieldset>
                       {!loading ? <div className="form-group form-group-half">
                            <input type="text" className="form-control" name="name" placeholder="Your Name" value={name} onChange={(e)=>{
                                setName(e.target.value);
                            }} required />
                        </div> : <div className="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div>}
                        
                        <div className="form-group form-group-half">
                            <input type="email" className="form-control" value={email} onChange={(e)=>{
                                setEmail(e.target.value);
                            }} name="name" placeholder="Your Email" required />
                        </div>

                        <div className="form-group">
                            <textarea value={details} className="form-control" placeholder="Enter Description" required onChange={(e)=>{
                                setDetails(e.target.value);
                            }}>{details}</textarea>
                        </div>
                        <label className="text-pirmary">Prove you are human: What is <span className="h6">{a} + {b}</span> ?</label>
                        <div className="form-group">
                            
                            <input type="text" className="form-control" name="name" placeholder={"What is " + a + " + " +b+" ?"} value={myanswer} onChange={(e)=>{
                                setMyAnswer(e.target.value);
                            }} required />
                        </div>

                        <div className="form-group wo-form-btn">
                            {!loading && (
                                <button
                                    type="button"
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        background: "#123652",
                                        color: "#fff",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        if (name === "" || email === "" || details === "") {
                                            setError("Please enter all required fields");
                                        } else {
                                            if(answer !== myanswer){
                                                setError("Math was incorrect, try again");
                                                toast({
                                                    description: "Math was incorrect, try again",
                                                    position: "top-right",
                                                    status: "error",
                                                  });
                                                reload();
                                            }else{
                                                sendMessage();
                                            }
                                            
                                        }
                                    }}
                                >
                                   
                                       Submit
                                  
                                </button>
                            )}

                            {loading && (
                                <div className="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default ContactForm
