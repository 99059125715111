import React from "react";
import { Link } from "react-router-dom";

function VehicleInfo({ vehicle }) {
  function numwithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (!vehicle) {
    return null;
  }
  return (
    <>
      <aside id="wo-sidebar" className="wo-sidebar">
        <div className="wo-sidebarprice">
          <div className="wo-sidebarprice__title">
            <h3>Vehicle Asking Price</h3>
          </div>
          <div className="wo-sidebarprice__content">
            <div className="wo-priceinfo">
              <div className="wo-priceinfo__final">
                <strong>
                  <sup>$</sup>
                  {numwithCommas(vehicle.price)}
                </strong>
                {vehicle.negotiable && <span>Negotiable Price</span>}
              </div>
              <div className="wo-priceinfo__contact">
                <Link to="#" className="wo-contectnum">
                  <i className="fas fa-phone" />
                  {/* Click To Show Number */}
                  <span>
                  +263 77 578 8875
                  </span>
                </Link>
                
              </div>
              <div className="wo-priceinfo__link">
                <span>
                  Mention
                  <Link to="#">ID: {vehicle.id}</Link>
                  when calling for quick assistance
                </span>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <aside className="wo-sidebar mt-3">
        <div className="wo-sidebarprice">
          <div className="wo-sidebarprice__title">
            <h3>Vehicle Main Features</h3>
          </div>
          <div className="wo-sidebarprice__content">
            <div className="wo-vsingledetails">
              <div className="wo-vsingledetails__content p-2">
                {/* <div className="wo-description">
                  <p>
                    Architecto beataie vitae dicta sunt explicabo. Nemo
                    enimnation ipsam toram voluptatem quia voluptas sit
                    aspernatur adipisicing elitiams sed do eiusmod tempor
                    incididunt.
                  </p>
                </div> */}
                <div className="wo-featureslist">
                  <ul>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-03.png"
                            }
                            alt="img description"
                          />
                          Body Type :
                        </strong>
                        <span>{vehicle.body}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-01.png"
                            }
                            alt="img description"
                          />
                          Engine Capacity :
                        </strong>
                        <span>{vehicle.cc}cc</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-13.png"
                            }
                            alt="img description"
                          />
                          Transmission :
                        </strong>
                        <span>{vehicle.transmission}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-08.png"
                            }
                            alt="img description"
                          />
                          Fuel Type :
                        </strong>
                        <span>{vehicle.fuel}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-05.png"
                            }
                            alt="img description"
                          />
                          Drive :
                        </strong>
                        <span>{vehicle.drivetrain}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={process.env.PUBLIC_URL + "/images/theme-icon/img-04.png"
                            }
                            alt="img description"
                          />
                          Rim Size :
                        </strong>
                        <span>{vehicle.rim}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-02.png"
                            }
                            alt="img description"
                          />
                          Assembly :
                        </strong>
                        <span>{vehicle.assembly}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-07.png"
                            }
                            alt="img description"
                          />
                          Central Lock :
                        </strong>
                        <span>{vehicle.central_lock && `Yes`}</span>
                      </div>
                    </li>

                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-04.png"
                            }
                            alt="img description"
                          />
                          Color
                        </strong>
                        <span>{vehicle.color}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-09.png"
                            }
                            alt="img description"
                          />
                          Steering Type :
                        </strong>
                        <span>{vehicle.steering}</span>
                      </div>
                    </li>

                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-10.png"
                            }
                            alt="img description"
                          />
                          Windows :
                        </strong>
                        <span>
                          {vehicle.windows ? `Power ` : `Manual `}
                          Windows
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-12.png"
                            }
                            alt="img description"
                          />
                          Sunroof :
                        </strong>
                        <span>{vehicle.sunroof && `Yes`}</span>
                      </div>
                    </li>
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-11.png"
                            }
                            alt="img description"
                          />
                          Seats :
                        </strong>
                        <span>{vehicle.seats}</span>
                      </div>
                    </li>
                    {vehicle.load.length > 0 && (
                      <li>
                        <div className="wo-featureslistcontent">
                          <strong>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/vehicle-single/features/icon/img-07.png"
                              }
                              alt="img description"
                            />
                            Load Cap :
                          </strong>
                          <span>{vehicle.load}</span>
                        </div>
                      </li>
                    )}
                    <li>
                      <div className="wo-featureslistcontent">
                        <strong>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/vehicle-single/features/icon/img-11.png"
                            }
                            alt="img description"
                          />
                          Seats :
                        </strong>
                        <span>{vehicle.seats}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}

export default VehicleInfo;
