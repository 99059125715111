import React, { useState, useEffect } from 'react';
import Select from 'react-select';


function Color({color}) {
    const [option, setOption] = useState();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));



    useEffect(() => {

        if(color){
            let array = Object.entries(color);
            let colorOptions = [];
            array.map((arr) => {
                colorOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('color') === arr[0]) {
                    setOption(colorOptions[colorOptions.length - 1]);
                }
                return arr;
            });
    
    
            setOptions(colorOptions);
    
            setLoading(false);
        }
        

    }, [color, urlParams]);

    return (
        <Select
        className="w-100 react-select-container mr-1"
        placeholder="Color"
        isSearchable
        name="color"
        value={option}
        isLoading={loading}
        isClearable
        options={options}
        onChange={(e) => {
            if (!e) {
                urlParams.delete("color");
                //urlParams.set("page",1);
                setOption(null);
            } else {
                setOption(e);
                urlParams.set("color", e.value);
            }

            
        }}
        />
    )
}

export default Color
