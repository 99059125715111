import React, { useState, useEffect, useCallback } from 'react'
import axiosInstance from '../../../helpers/axios';
import Select from "react-select";

function Vehicle({ makes }) {

    const [models, setModels] = useState([]);
    const [make, setMake] = useState();
    const [model, setModel] = useState();

    const [urlParams] = useState(new URLSearchParams(window.location.search));

    const [loading, setloading] = useState(true);
    const [modelloading, setModelLoading] = useState(false);

    const getModels = useCallback((e)=>{
        setModelLoading(true);
        axiosInstance
            .get(`ajax/models/${e}`)
            .then((res) => {
                setModelLoading(false);
                setModels(res.data);
                if (urlParams.get('model')) {
                    res.data.map((model) => {
                        if (model.slug === urlParams.get('model')) {
                            setModel(model);
                        }
                        return model;
                    });
                }
            })
            .then();
    },[urlParams]);

    useEffect(() => {

        setloading(true);
        makes.map((make) => {

            if (make.slug === urlParams.get("make")) {
                setMake(make);
                getModels(make.slug);
            }
            return make;
        });
        setloading(false);
    },[makes, urlParams, getModels]);


    

    return (
        <>
            <li className="wo-searchftype">
                <Select
                    className="w-100 react-select-container"
                    placeholder="Vehicle Make"
                    isSearchable
                    isClearable
                    isLoading={loading}
                    value={make}
                    required
                    name="make"
                    onChange={(e) => {
                        if (e) {
                            urlParams.delete("model");
                            urlParams.set("make", e.slug);
                            setModels([]);
                            setModel(null);
                            getModels(e.slug);
                            setMake(e);
                        } else {
                            urlParams.delete("make");
                            urlParams.delete("model");
                            setModel(null);
                            setModels([]);
                            setMake(null);
                        }

                        // window.location.href = location.pathname + "?" + urlParams.toString();
                    }}
                    options={makes}
                />
            </li>
            <li className="wo-searchftype">
                <Select
                    className="w-100 react-select-container"
                    placeholder="Vehicle Model"
                    isSearchable
                    required
                    name="model"
                    isClearable
                    isLoading={modelloading}
                    value={model}
                    options={models}
                    onChange={(e) => {
                        
                        if (e) {
                            urlParams.set("model", e.slug);
                            setModel(e);
                        } else {
                            urlParams.delete("model");
                            setModel(null);
                        }

                       
                    }}
                />
            </li>

        </>
    )
}

export default Vehicle
