import React, { useState, useEffect } from 'react'
import Select from "react-select";

function Body({ body }) {


    const [options, setOptions] = useState([]);
    const [bodyOption, setBody] = useState();
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));


    useEffect(() => {

        if(body){
            let bodyarray = Object.entries(body);
            let bodyOptions = [];
            bodyarray.map((arr) => {
                bodyOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('body') === arr[0]) {
                    setBody(bodyOptions[bodyOptions.length - 1]);
                }
                return arr;
            });
            setOptions(bodyOptions);
            setLoading(false);
        }
        
    }, [body, urlParams])


    return (
        <Select
            className="w-100 react-select-container mr-1"
            placeholder="Body Type"
            isSearchable
            name="body"
            value={bodyOption}
            isLoading={loading}
            isClearable
            options={options}
            onChange={(e) => {
                if (!e) {
                    urlParams.delete("body");
                    //urlParams.set("page",1);
                    setBody(null);
                } else {
                    setBody(e);
                    urlParams.set("body", e.value);
                }

               
            }}
        />

    )
}

export default Body
