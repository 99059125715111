import React from "react";
import { Skeleton, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import VehicleSlider from "./VehicleSlider";
import { useDispatch } from "react-redux";
import { addCompare } from "../../helpers/redux/compareSlice";

function VehicleDetails({ vehicle }) {
  const dispatch = useDispatch();

  console.log(sessionStorage.getItem("comparelist"));
  if (!vehicle) {
    return (
      <Stack>
        <div className="wo-vsinglehead">
          <div className="wo-tags">
            <Skeleton>
              <Link to="#" className="wo-tag-featured">
                Featured
              </Link>
            </Skeleton>
            <Skeleton>
              <Link to="#" className="wo-tag-rend">
                Negotiable
              </Link>
            </Skeleton>
            <Skeleton>
              <Link to="#" className="wo-tag-sale">
                <i className="fas fa-not-equal mr-2"></i>
                Compare Vehicle
              </Link>
            </Skeleton>
          </div>
          <div className="wo-vsinglehead__title">
            <Skeleton>
              <h2>This is a test vehicle</h2>
            </Skeleton>
          </div>
          <ul className="wo-vsinglehead__share">
            <li>
              <Skeleton>
                <span>
                  <i className="fas fa-bookmark" />
                  ID: xxxxxxx
                </span>
              </Skeleton>
            </li>

            <li>
              <Skeleton>
                <Link to="#">
                  <i className="fas-share-alt" />
                  Share
                </Link>
              </Skeleton>
            </li>
          </ul>
          <br></br>
          <Skeleton>
            <img
              src="/images/vehicle-single/slider/img-01.jpg"
              className=""
              alt=""
            />
          </Skeleton>
        </div>
      </Stack>
    );
  }
  return (
    <>
      <div className="wo-vsinglehead">
        <div className="wo-tags">
          {vehicle?.featured === true && (
            <Link to="#" className="wo-tag-featured">
              Featured
            </Link>
          )}
          {vehicle.negotiable === true && (
            <Link to="#" className="wo-tag-rent">
              Negotiable
            </Link>
          )}
          <Link
            to="#"
            className="wo-tag-sale"
            onClick={() => {
              console.log(dispatch(addCompare(vehicle)));
            }}
          >
            <i className="fas fa-not-equal mr-2"></i>
            Compare Vehicle
          </Link>
        </div>
        <div className="wo-vsinglehead__title">
          <h2>
            {vehicle.year} {vehicle.make} {vehicle.model}
          </h2>
        </div>
        <ul className="wo-vsinglehead__share">
          <li>
            <span>
              <i className="fas fa-bookmark" />
              ID: {vehicle.id}
            </span>
          </li>

          <li>
            <Link to="#">
              <i className="fas fa-share-alt" />
              Share
            </Link>
          </li>
        </ul>
      </div>

      <VehicleSlider vehicle={vehicle} />

      <ul className="wo-vslider-features">
        <li>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/vehicle-single/features/img-01.png"
            }
            alt="img description"
          />
          <h4>{vehicle.year}</h4>
          <span>Model Year</span>
        </li>
        <li>
          <img
            src="/images/vehicle-single/features/img-02.png"
            alt="img description"
          />
          <h4>{vehicle.distance} km</h4>
          <span>Total Drived</span>
        </li>
        <li>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/vehicle-single/features/img-03.png"
            }
            alt="img description"
          />
          <h4>{vehicle.fuel}</h4>
          <span>Fuel Type</span>
        </li>
        <li>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/vehicle-single/features/img-04.png"
            }
            alt="img description"
          />
          <h4>{vehicle.transmission}</h4>
          <span>Transmission</span>
        </li>
        <li>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/vehicle-single/features/img-05.png"
            }
            alt="img description"
          />
          <h4>{vehicle.cc} CC</h4>
          <span>Engine Capacity</span>
        </li>
      </ul>

      <div>
        <div className="wo-vsingledetails">
          {/* <div className="wo-vsingledetails__title">
            <h3>Vehicle Description</h3>
          </div> */}
          {/* <div className="wo-vsingledetails__content">
            <div className="wo-description">
              <p>
                Consectetur adipisicing elit sedaten do eiusmod tempor
                incididunt ut labore et dolore magna aliqua enim ad minim
                veniam, quis dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat nonat proident, sunt in culpa qui officia
                deserunt mollit anim perspiciatis unde.
              </p>
              <p>
                Domnis iste natus error sit voluptatem accusantium doloremque
                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                aut fugit, sed quia consequuntur magni dolores eos quistan miki
                ratione voluptatem sequi nesciunt. Neque porro quisquam estiam.
              </p>
            </div>
          </div> */}
        </div>

      </div>
    </>
  );
}

export default VehicleDetails;
