import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="wo-footer-wrap">
      <img
        src="/images/footer/shape.png"
        className="wo-footershape  d-none d-sm-block"
        alt="img description"
      />
      <div className="wo-footer">
        <div className="container  d-none d-sm-block">
          <div className="row">
            <div className="col-12">
              <div className="wo-fpostwrap">
                <div className="wo-fpostwrap__title">
                  <h4>Planning To Upgrade Your Car?</h4>
                  <h4>
                    Its Easy Just <span>Search, Compare & Vrooom</span>
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="wo-fpost">
                      <img
                        src="/images/footer/img-03.jpg"
                        alt="img description"
                      />
                      <h4>Best Priced Parts</h4>
                      <span>
                        Buy quality comparable to original parts. And all that at unbeatable prices!
                      </span>
                      <Link to="/parts">Browse Parts</Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="wo-fpost">
                      <img
                        src="/images/footer/img-02.jpg"
                        alt="img description"
                      />
                      <h4>Quality of Vehicles</h4>
                      <span>
                        Browse through our quality used vehicles section and purchase your new car
                      </span>
                      <Link to="/vehicles">Search Vehicles</Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="wo-fpost">
                      <img
                        src="/images/footer/img-01.jpg"
                        alt="img description"
                      />
                      <h4>Secure Way of Payment</h4>
                      <span>
                        We offer secure methods of payments, including not limited to Cash on Delivery
                      </span>
                      <Link to="#">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="wo-fjoinnow">
                <div className="wo-fjoinnow__title">
                  <h4>
                    Looking For A Mechanic?{" "}
                    <span>Browse Today & Get Best Offers</span>
                  </h4>
                </div>
                <div className="wo-fjoinnow__info">
                  <p>
                    Consectetur adipisicing elitem sed do eiusmod tempor
                    incididunt bore et dolore aliqua enim ad minim veniam quis
                  </p>
                </div>
                <div className="wo-fjoinnow__btn">
                  <Link to="signup.html" className="wo-btn">
                    Browse Merchanics
                  </Link>
                </div>
                <div
                  id="particlesfooter-js"
                  className="wo-particles particles-js"
                ></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="wo-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wo-footernav-content">
                <p className="wo-copyrights">
                  Copyrights © 2021 by <a href="mailto:ianaleckm@gmail.com">Kloow Studio</a> All
                  Rights Reserved.
                </p>
                {/* <nav className="wo-footernav">
                  <ul>
                    <li>
                      <Link to="#">Legal Privacy</Link>
                    </li>
                    <li>
                      <Link to="#">Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Careers</Link>
                    </li>
                    <li>
                      <Link to="#">Support & Help</Link>
                    </li>
                  </ul>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
