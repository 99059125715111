import "./App.css";
import theme from "./helpers/chakra/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./layout/Home";
import Footer from "./layout/Footer";
import CarsPage from "./layout/CarsPage";
import CarPage from "./layout/CarPage";
import CompareCars from "./layout/CompareCars";
import PartsPage from "./layout/PartsPage";
import PartPage from "./layout/PartPage";
import Login from "./layout/Login";
import { AuthProvider } from "./helpers/google/AuthContext";
import ProtectedRoute from "./helpers/basic/ProtectedRoute";
import CheckoutPage from "./layout/CheckoutPage";
import ResetPassword from "./layout/ResetPassword";
import SignUp from "./layout/SignUp";
import ScrollToTop from "./helpers/basic/ScrollToTop";
import Order from "./layout/Order";
import ContactUs from "./layout/ContactUs";
import SellVehicle from "./layout/SellVehicle";
import MyVehicles from "./layout/MyVehicles";

function App() {
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/vehicles" component={CarsPage} />
            <Route path="/vehicle/:uid" component={CarPage} />
            <Route path="/compare-vehicles" exact component={CompareCars} />
            <Route path="/parts/:category_slug?" component={PartsPage} />
            <Route path="/part/:uid" component={PartPage} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/checkout" component={CheckoutPage} />
            <Route path="/order/:uid?" component={Order} />
            <Route path="/contact" component={ContactUs} />
            {/* <ProtectedRoute path="/checkout" exact component={CheckoutPage} /> */}
            <ProtectedRoute path="/sell-vehicle" exact component={SellVehicle} />
            <Route path="/my-vehicles" component={MyVehicles} />
          </Switch>
          <Footer />
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
