import React, {useState,useEffect} from 'react'
import Select from "react-select";


function Transmission({transmission}) {

    const [options, setOptions] = useState([]);
    const [transmissionOption, setTransmissionOption] = useState();
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));


    useEffect(() => {

        if(transmission){
            let transmissionarray = Object.entries(transmission);
            let transmissionOptions = [];
            transmissionarray.map((arr) => {
                transmissionOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('transmission') === arr[0]) {
                    setTransmissionOption(transmissionOptions[transmissionOptions.length - 1]);
                }
                return arr;
            });
    
    
            setOptions(transmissionOptions);
    
            setLoading(false);
        }
        

    }, [transmission, urlParams])
    return (
        <Select
            className="w-100 react-select-container mr-1"
            placeholder="Transmission"
            isSearchable
            name="transmission"
            value={transmissionOption}
            isClearable
            isLoading={loading}
            options={options}
            onChange={(e) => {
                if (!e) {
                    urlParams.delete("transmission");
                    //urlParams.set("page",1);
                    setTransmissionOption(null);
                } else {
                    setTransmissionOption(e);
                    urlParams.set("transmission", e.value);
                }

               
            }}
        />
    )
}

export default Transmission
