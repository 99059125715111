import React, { useEffect, useState, useCallback } from 'react'
import Header from '../components/header/Header'
import { useParams, Link } from "react-router-dom";
import axiosInstance from '../helpers/axios';

function Order() {


    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { uid } = useParams();

    const [searchItem, setSearchItem] = useState(uid);



    const [isinit] = useState(true);
    const [serverMessage, setServerMessage] = useState("");
    const [serverClass, setClass] = useState('danger');
    const [cart, setCart] = useState([]);
    const [schedule, setSchedule] = useState("");

    const getOrder = useCallback(
        () => {
            setLoading(true);
        axiosInstance
            .get('/ajax/order/' + searchItem)
            .then((res) => {
                return res.data;
            }).then((data) => {
                if (!data.order) {
                    let message = "Failed to retrieve order. Please try again later";
                    if (data.message) {
                        message = data.message;
                    }
                    setError(message);
                } else {
                    setOrder(data.order);
                    setCart(data.cart);
                    setServerMessage(data.message);
                    setSchedule(data.schedule);
                    setClass(data.class);
                }
                setLoading(false);
            }).catch((err) => {
                let message = "Failed to retrieve order. Please try again later";
                setError(message);
                setLoading(false);
            });
        },
        [searchItem],
    )



    useEffect(() => {

        if (uid) {
            getOrder();
        } else {
            setLoading(false);
        }

    }, [getOrder, uid]);


    return (
        <>
            <Header type="parts" />
            <main className="wo-main overflow-hidden">
                <div className="wo-main-section p-2 mt-5">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h4>Track Order</h4>
                            {!order && (
                                <>
                                    <div className="d-flex w-100">
                                        <input
                                            className=" flex-grow react-select-container"
                                            style={{
                                                alignItems: "center",
                                                backgroundColor: "hsl(0, 0%, 100%)",
                                                borderColor: "hsl(0, 0%, 80%)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                cursor: "default",
                                                padding: "6px",
                                                marginRight: "10px",
                                                flexGrow: 1,
                                            }}
                                            placeholder="Enter Order UID"
                                            name="uid"
                                            value={searchItem}
                                            onChange={(e) => {
                                                setSearchItem(e.target.value);
                                                if (e.target.value !== "") {
                                                    setError("");
                                                } else {
                                                    setError("Please enter Order UID");
                                                }
                                            }}
                                        />
                                        {!loading && (
                                            <button
                                                type="button"
                                                style={{
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    background: "#123652",
                                                    color: "#fff",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={() => {
                                                    if (searchItem === "") {
                                                        setError("Please enter Order UID");
                                                    } else {
                                                        getOrder();
                                                    }
                                                }}
                                            >
                                                <small>
                                                    <i className="fa fa-search"></i>
                                                </small>
                                            </button>
                                        )}

                                        {loading && (
                                            <div className="lds-ripple">
                                                <div></div>
                                                <div></div>
                                            </div>
                                        )}
                                    </div>
                                    {error.length > 0 && (
                                        <small className="text-danger">{error}</small>
                                    )}
                                </>
                            )}

                            <p className="mt-2 text-muted small">
                                We've made it easy for you to track your orders. Simply enter
                                the order uid and you will be able to see your order details,
                progress and more. Need help ?{" "}
                                <Link to="/contact">Contact Us</Link>
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        {order && (
                            <form className="w-100">
                                <div className="row">
                                    <div
                                        style={{ minHeight: "400px" }}
                                        className={`col-sm-12 col-md-12 col-lg-5 offset-lg-1`}
                                    >
                                        {serverMessage && !loading && (
                                            <div className="wo-fpost">
                                                <img
                                                    src="/images/footer/img-02.jpg"
                                                    alt="img description"
                                                />
                                                <h4>Order Placed</h4>
                                                <p className="text-muted">{serverMessage}</p>
                                                <Link to="/">Go Home</Link>  <Link to="#" onClick={() => { setSearchItem(""); setOrder(null) }}>Track Another Order</Link>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`col-sm-12 col-md-12 col-lg-5`}>
                                        <div className="wo-settingsave p-2">
                                            <div className="wo-settingsave__title">
                                                <h2>Your Order : {order.order_uid}</h2>
                                                {schedule.length > 2 && (
                                                    <p className="alert alert-primary text-dark">
                                                        {schedule}
                                                    </p>
                                                )}
                                            </div>

                                            {cart.length > 0 ? (
                                                <>
                                                    <div className="wo-dbpost p-3 mt-5">
                                                        <table className="order-summary">
                                                            <tbody>
                                                                {cart.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="product-summary">
                                                                            <h3>
                                                                                <span className="product-title">
                                                                                    {item.item_name}
                                                                                </span>
                                                                                <span className="item-quantity-price">
                                                                                    {item.item_count} x $
                                        {item.item_count * item.item_price}
                                                                                </span>
                                                                            </h3>
                                                                            <p>
                                                                                Variation:{" "}
                                                                                {
                                                                                    JSON.parse(item.item_data).variation
                                                                                        .variation_name
                                                                                }
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <table className="checkout-subtotals">
                                                            <tbody className="d-block">
                                                                <tr>
                                                                    <td className="label">SubTotal</td>
                                                                    <td>${order.order_subtotal}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="label">
                                                                        {order.order_paymentMethod}
                                                                    </td>
                                                                    <td>${order.order_shipping}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="mt-3 d-flex">
                                                            <strong style={{ flexGrow: 1 }}>Total</strong>
                                                            <strong>${order.order_total}</strong>
                                                        </div>
                                                    </div>
                                                    {serverMessage && isinit && (
                                                        <div
                                                            className={`alert alert-${serverClass} alert-dismissible fade show mt-3`}
                                                            role="alert"
                                                        >
                                                            {serverMessage}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="wo-dbpost p-3 mt-5">
                                                    <h6 className="text-center">
                                                        <i className="fas fa-shopping-cart h3"></i>
                                                        <br></br>Cart is empty!
                          </h6>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </main>

        </>
    )
}

export default Order
