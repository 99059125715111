import { configureStore } from "@reduxjs/toolkit";
import carsSlice from "./carsSlice";
import cartSlice from "./cartSlice";
import brandsSlice from "./brandsSlice";
import makeSlice from "./makeSlice";
import categorySlice from "./categorySlice";
import compareSlice from "./compareSlice";
import myVehicleSlice from "./myVehicleSlice";
import vehicleSlice from "./vehicleSlice";

export default configureStore({
  reducer: {
    cart: cartSlice,
    vehicles: carsSlice,
    makes: makeSlice,
    brands: brandsSlice,
    categories: categorySlice,
    comparelist: compareSlice,
    myvehicles: myVehicleSlice,
    myvehicle:vehicleSlice
  },
});
