import React, { useContext, useState, useEffect } from "react";
import { auth, google } from "./firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function googlesign() {
    return auth.signInWithPopup(google);
  }

  function changeName(name) {
    const update = {
      displayName: name,
    };
    return auth.currentUser.updateProfile(update);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    sessionStorage.removeItem('token');
    return auth.signOut();
  }

  function reset(email) {
    return auth.sendPasswordResetEmail(email);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {

      if(user){
        sessionStorage.setItem('token',user.uid);
      }
      
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    reset,
    changeName,
    googlesign,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
