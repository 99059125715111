import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, reduceCart } from "../../helpers/redux/cartSlice";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Center,
} from "@chakra-ui/react";

import { createIcon } from "@chakra-ui/react";

export const EmptyCartIcon = createIcon({
  displayName: "EmptyCartIcon",

  viewBox: "0 0 496 496",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path d="m456 176h-168v-136c0-22.054688-17.945312-40-40-40s-40 17.945312-40 40v136h-168c-22.054688 0-40 17.945312-40 40 0 19.3125 13.769531 35.472656 32 39.191406v200.808594c0 22.054688 17.945312 40 40 40h352c22.054688 0 40-17.945312 40-40v-200.808594c18.230469-3.71875 32-19.871094 32-39.191406 0-22.054688-17.945312-40-40-40zm-232-136c0-13.230469 10.769531-24 24-24s24 10.769531 24 24v160c0 13.230469-10.769531 24-24 24s-24-10.769531-24-24zm200 440h-352c-13.230469 0-24-10.769531-24-24v-200h400v200c0 13.230469-10.769531 24-24 24zm32-240h-416c-13.230469 0-24-10.769531-24-24s10.769531-24 24-24h168v8c0 22.054688 17.945312 40 40 40s40-17.945312 40-40v-8h168c13.230469 0 24 10.769531 24 24s-10.769531 24-24 24zm0 0" />
      <path d="m192 288c-17.648438 0-32 14.351562-32 32v96c0 17.648438 14.351562 32 32 32s32-14.351562 32-32v-96c0-17.648438-14.351562-32-32-32zm16 128c0 8.824219-7.175781 16-16 16s-16-7.175781-16-16v-96c0-8.824219 7.175781-16 16-16s16 7.175781 16 16zm0 0" />
      <path d="m112 288c-17.648438 0-32 14.351562-32 32v96c0 17.648438 14.351562 32 32 32s32-14.351562 32-32v-96c0-17.648438-14.351562-32-32-32zm16 128c0 8.824219-7.175781 16-16 16s-16-7.175781-16-16v-96c0-8.824219 7.175781-16 16-16s16 7.175781 16 16zm0 0" />
      <path d="m304 288c-17.648438 0-32 14.351562-32 32v96c0 17.648438 14.351562 32 32 32s32-14.351562 32-32v-96c0-17.648438-14.351562-32-32-32zm16 128c0 8.824219-7.175781 16-16 16s-16-7.175781-16-16v-96c0-8.824219 7.175781-16 16-16s16 7.175781 16 16zm0 0" />
      <path d="m384 288c-17.648438 0-32 14.351562-32 32v96c0 17.648438 14.351562 32 32 32s32-14.351562 32-32v-96c0-17.648438-14.351562-32-32-32zm16 128c0 8.824219-7.175781 16-16 16s-16-7.175781-16-16v-96c0-8.824219 7.175781-16 16-16s16 7.175781 16 16zm0 0" />
      <path d="m240 192h16v16h-16zm0 0" />
      <path
        fill="currentColor"
        d="m416 160c44.113281 0 80-35.886719 80-80s-35.886719-80-80-80-80 35.886719-80 80 35.886719 80 80 80zm0-144c35.289062 0 64 28.710938 64 64s-28.710938 64-64 64-64-28.710938-64-64 28.710938-64 64-64zm0 0"
      />
      <path d="m368 72h96v16h-96zm0 0" />
    </>
  ),
});

function PartsTopSwitcher() {
  const cart = useSelector((state) => state.cart.value);
  const cars = useSelector((state) => state.myvehicles.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [length, setLength] = useState(cart.length);
  const checkoutbtn = React.useRef();
  useEffect(() => {
    if (cart.length > length) {
      onOpen();
    }
    setLength(cart.length);
  }, [cart, length, onOpen]);
  return (
    <div className="wo-header-btns">
      <button
        onClick={() => {
          onOpen();
        }}
        className="wo-btn wo-compare-btn"
      >
        <i className="fas fa-shopping-cart"></i> Cart
        {<em>{cart.length}</em>}
      </button>
      <Link to="/my-vehicles" className="wo-btn">
        My Vehicles
        {<em>{cars.length}</em>}
      </Link>
      <Drawer
        isOpen={isOpen}
        size="sm"
        placement="right"
        onClose={onClose}
        initialFocusRef={checkoutbtn}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Your cart basket</DrawerHeader>

          <DrawerBody>
            {cart.length < 1 ? (
              <Center height="100%">
                <div className="text-center">
                  <p>
                    <EmptyCartIcon color="red.500" w={20} h={20} />
                  </p>
                  <p>
                    <Center>Your Cart is empty</Center>
                  </p>
                </div>
              </Center>
            ) : (
              cart.map((item, index) => (
                <div
                  className="wo-narrow-searchfilter p-3"
                  key={item.variation.variation_id}
                >
                  <div className="d-flex">
                    {item.images.length > 0 && (
                      <img
                        key={item.images[0]}
                        src={item.image_base + item.images[0]}
                        width="70px"
                        alt="description"
                      />
                    )}
                    <div style={{ flex: "auto", marginLeft: "10px" }}>
                      <span style={{ fontWeight: "600" }}>{item.name}</span>{" "}
                      <br /> {item.category.name} (
                      {item.variation.variation_name})
                    </div>
                    <strong style={{ display: "flex" }}>
                      $
                      {(item.count * item.variation.variation_price).toFixed(2)}
                    </strong>
                  </div>

                  <small>
                    {item.shipping
                      ? `Shpping or Collection`
                      : `Collection Only`}
                  </small>
                  <hr className="mb-1" />
                  <div className="wo-partstock__content">
                    <form className="wo-vlaue-btn bg-white w-100">
                      <Link
                        to="#"
                        onClick={() => {
                          // reduceCart(item);
                          dispatch(reduceCart(item));
                        }}
                        className="wo-input-decrement"
                      >
                        -
                      </Link>
                      <small
                        style={{
                          margin: "auto",
                          fontWeight: "100",
                          fontSize: "14px",
                        }}
                      >
                        {item.count}
                      </small>
                      <Link
                        to="#"
                        onClick={() => {
                          let newitem = { ...item };
                          newitem.count = 1;
                          dispatch(addToCart(newitem));
                        }}
                        className="wo-input-increment"
                      >
                        +
                      </Link>
                    </form>
                  </div>
                </div>
              ))
            )}
          </DrawerBody>

          {cart.length > 0 && (
            <DrawerFooter className="d-block">
              <a href="/parts" isFullWidth>
                Continue Shopping
              </a>
              <br></br>
              <strong className="float-right mb-2">
                Subtotal $
                {cart
                  .reduce(
                    (acc, curr) =>
                      acc + curr.count * curr.variation.variation_price,
                    0
                  )
                  .toFixed(2)}
              </strong>

              <Link
                className="wo-btn w-100"
                to="/checkout"
                isFullWidth
                onClick={() => {
                  onClose();
                }}
              >
                Checkout
              </Link>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default PartsTopSwitcher;
