import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/toast";

function CarsTopSwitcher() {
  // const [cart, addToCart, reduceCart, vehicles] = useContext(CartContext);
  const comparelist = useSelector((state) => state.comparelist.value);
  const isInitialMount = useRef(true);
  const toast = useToast();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      toast({
        description: "Compare list updated!",
        position: "top-right",
        status: "success",
        variant: "top-accent",
        isClosable: false,
      });
    }
  }, [comparelist, toast]);
  return (
    <div className="wo-header-btns">
      <Link to="/compare-vehicles" className="wo-btn wo-compare-btn">
        Compare Vehicles<em>{comparelist.length}</em>
      </Link>
      <Link to="/sell-vehicle" className="wo-btn">
        Sell Vehicle
      </Link>
    </div>
  );
}

export default CarsTopSwitcher;
