import React, { useState, useEffect } from "react";
import HeroSearch from "./HeroSearch";
import HeroSlider from "./HeroSlider";
import axiosInstance from "../../helpers/axios";
import { useSelector, useDispatch } from "react-redux";
import { setMakes } from "../../helpers/redux/makeSlice";
import { setBrands } from "../../helpers/redux/brandsSlice";
import { setCategories } from "../../helpers/redux/categorySlice";

function HomeHero() {
  const [loading, setLoading] = useState(true);
  const [ads, setAds] = useState([]);
  const makes = useSelector((state) => state.makes.value);
  const [settings, setSettings] = useState({});
  // const brands = useSelector((state) => state.brands.value);
  // const categories = useSelector((state) => state.categories.value);
  const dispatch = useDispatch();
  // const [makes, setMakes] = useState([]);
  // const [brands, setBrands] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/ajax/")
      .then((res) => {
        //console.log(res.data);
        if (res.data.error !== undefined && res.data.error === false) {
          setAds(res.data.ads);
          dispatch(setMakes(res.data.makes));
          // console.log(makes);
          // dispatch(setBrands(res.data.brands));
          dispatch(setCategories(res.data.categories));
          setMakes(res.data.makes);
          setBrands(res.data.brands);
          setSettings(res.data.settings);
        }
        setLoading(false);
      })
      .then()
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);
  return (
    <div className="wo-mainbanner-wrap">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="wo-banner">
              <HeroSearch loading={loading} settings={settings} makes={makes} />
              <HeroSlider loading={loading} ads={ads} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
