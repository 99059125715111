import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../google/AuthContext";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login?redirect=${pathname}`} />
        );
      }}
    ></Route>
  );
}
