import React, { useState, useEffect } from 'react';
import Select from 'react-select';



function DriveTrain({drive}) {

    const [option, setOption] = useState();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [urlParams] = useState(new URLSearchParams(window.location.search));



    useEffect(() => {


        if(drive){
            let array = Object.entries(drive);
            let driveOptions = [];
            array.map((arr) => {
                driveOptions.push({ value: arr[0], label: arr[1] });
                if (urlParams.get('drive') === arr[0]) {
                    setOption(driveOptions[driveOptions.length - 1]);
                }
                return arr;
            });
    
    
            setOptions(driveOptions);
    
            setLoading(false);
        }
        

    }, [drive, urlParams]);


    return (
        <Select
        className="w-100 react-select-container mr-1"
        placeholder="Drive Train"
        isSearchable
        name="drive"
        value={option}
        isLoading={loading}
        isClearable
        options={options}
        onChange={(e) => {
            if (!e) {
                urlParams.delete("drive");
                //urlParams.set("page",1);
                setOption(null);
            } else {
                setOption(e);
                urlParams.set("drive", e.value);
            }

           
        }}
        />
    )
}

export default DriveTrain
